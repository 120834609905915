<template>    
    <div class="product mb-4 c-pointer p-3" onmouseover="this.style.background='#f2f2f2';" onmouseout="this.style.background='white';">
        <!--<div class="free-shipping h3 font-weight-bold text-center bg-danger text-white p-2" v-bind:class="{hidden:!product.freeShipping}">FREE SHIPPING</div>-->
        <div v-if="product.id<4" class="free-shipping h3 font-weight-bold text-center text-white p-2" style="background-color: #983222" v-bind:class="{hidden:product.id>3}">CREATE YOUR OWN VARIETY PACK</div>
        <div v-else class="free-shipping h3 font-weight-bold text-center text-white p-2" style="background-color: #983222"  v-bind:class="{show:product.id<4}">INDIVIDUAL SNACK PACKS</div>


        <!--<div class="free-shipping h3 font-weight-bold text-center text-white p-2" style="background-color: #983222" v-bind:class="{hidden:product.id<4}">INDIVIDUAL SNACK SIZES</div>-->
        <div class="prod-image">
            <el-image :src="product.imageSrc">
                <template #error>
                    <div class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                    </div>
                </template>
            </el-image>
        </div>

        <div class="prod-info">
            <div class="prod-title font-weight-bold text-center mt-1">{{product.name}}</div>
            <!-- <div class="prod-price d-flex" v-if="product.price>0"><span class="bg-warning">${{product.price}}</span></div>-->
        </div>
      

    </div>
</template>

<script>

    export default {
        name: "Product",
        components: {},
        props: {
            product: Object
        },
        data() {
            return {
              
            }
        },       
        created() {
         
        },
        methods: {
           
        }
    }
</script>

<style scoped>
    .product {
        position:relative;
        border: 1px solid #ccc;
        border-radius:10px;
    }
    img {
        width: 100%;
        height: 360px;
        object-fit: contain;
    }
    .price {
        background: #EA6E41;
        border-radius: 100%;
        font-weight: 700;
        font-size: 1rem;
        color: white;
    }
    .prod-info {
        background-repeat: no-repeat;
        background-position: center;
        font-size: 1.2rem;
        height: 100px;       
    }
    .prod-title {
        font-size: 1.5rem;
        height: 200px;
        text-align: center;
        font-weight:bold;
    }
    .prod-price {
        position: absolute;
        right: 10px;
        bottom: 10px;
        color: rgb(118,108,105);
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .prod-price span {
        border-radius: 100%;
        width: 65px;
        height: 65px;
        text-align: center;
        line-height: 4rem;
    }
    .prod-image{
        height:400px;
    }
</style>