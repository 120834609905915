<template>
    <div class="container mt-5">
        <el-tabs :tab-position="tabsPosition" style="min-height: 400px; overflow: auto;">
            <el-tab-pane label="Profile"><profile></profile></el-tab-pane>
            <el-tab-pane label="Address"><Address></Address></el-tab-pane>
            <!--<el-tab-pane label="Identity Verification"><IdentityVerification></IdentityVerification></el-tab-pane>
            <el-tab-pane label="Password & Security"><PasswordAndSecurity></PasswordAndSecurity></el-tab-pane>-->
            <el-tab-pane label="Payment Methods" v-if="checkIfUserIsOrganizer">
                <!--<section class="h5 font-weight-medium mt-5">
                    <div class="row col-md-10 mb-2">
                        <h5 style="color: #983222">Thank you for participating in a Colorado Jack Fundraiser! We are ready to prepare your check for 50% of your gross sales (sales less shipping costs). </h5>
                    </div>
                </section>-->
                <section class="h5 font-weight-medium mt-5">
                    <span>FUNDRAISER PAYOUT INFORMATION</span>
                    <h5 class="mt-3">Thank you for participating in a Colorado Jack Fundraiser!</h5>
                    <h6>Click on the fundraiser title below and complete the form then click submit to provide your information to our staff for final payout review. </h6>
                    <!-- <span class="float-right"><el-button icon="el-icon-edit" circle @click="editMode=true"></el-button></span>-->
                </section>
                <template v-if="editMode">
                    <div class="row mt-3">
                        <div class="col-12 col-sm-6">
                            <el-form :model="event" :rules="event1Rules" ref="event1Form" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>
                                <el-collapse class="ml-3" style="white-space: nowrap;overflow: hidden;text-overflow: clip">
                                    <el-collapse-item v-for="event in events" :title="event.title" :name="event.id" :key="event.id" @change="selectedevent(event)" style="color: #983222; font-size:medium">
                                        <div v-if="event.status=='Ended'" class="col-12" style="background-color:lightgrey">                                           
                                            <div class="row col-md-12 mb-2" style="white-space: normal;overflow: auto;">
                                                <h6>We are ready to prepare your check for 50% of your gross sales (sales less shipping costs). </h6>
                                            </div>                                           
                                            <section style="height: 720px;white-space: normal;overflow: auto;text-overflow: ellipsis">
                                                <div class="row">
                                                    <div class="col-6 font-weight-medium text-left" style="font-size:14px">FUNDRAISER TITLE</div>
                                                    <div class="col-6  font-weight-medium text-left" style="font-size:14px">ESTIMATED PAYOUT</div>
                                                </div>
                                                <hr class="m-1" />
                                                <div class="row ">
                                                    <div class="col-6 font-weight-medium  text-left">{{event.title}}</div>
                                                    <div class="col-6 font-weight-medium  text-left">${{event.allStoresSubTotal / 2}}</div>
                                                </div>
                                                <hr class="m-1 mb-2" />
                                                <section>
                                                    <el-form-item label="MAKE CHECK PAYABLE TO:" prop="eventPayToName">
                                                        <el-input type="text" v-model="event.eventPayToName" autocomplete="off" v-focus v-fancylabel v-on:keyup.enter="submitChanges"></el-input>
                                                    </el-form-item>
                                                </section>
                                                <section style="padding:0px">
                                                    <el-form-item label="ADDRESS TO SEND CHECK TO:" prop="eventPayToAddress">
                                                        <el-input type="text" v-model="event.eventPayToAddress" autocomplete="off" v-focus v-fancylabel v-on:keyup.enter="submitChanges"></el-input>
                                                    </el-form-item>
                                                </section>
                                                <section style="padding:0px">
                                                    <h6 class="text-center" style="font-weight:500">Please upload your W-9 information! If you need the W-9 form you can fill out a PDF online at <a href="https://www.irs.gov/pub/irs-pdf/fw9.pdf" target="_blank">Visit irs w-9</a> then upload to this site.  If you have questions email us at <a href="mailto:info@coloradojackgiveback.com">info@coloradojackgiveback.com</a>.</h6>
                                                    <div style="height:200px;margin: 0 auto; margin-bottom:20px">
                                                        <el-upload class="avatar-uploader" drag:action="url+filename+query" :on-change="w9Selected" :auto-upload="false" drag>
                                                            <i class="el-icon-upload"></i>
                                                            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                                                            <div class="el-upload__tip">Only files with an and .pdf .jpg .jpeg extension</div>
                                                        </el-upload>
                                                    </div>
                                                </section>
                                                <hr />
                                                <section style="padding:0px">
                                                    <el-form-item>
                                                        <div style="text-align:center;justify-content:center">
                                                            <h6 v-if="submitPayinfo&&event.w9file!=null" style="color: #983222; font-size:large">Thanks, we have submitted your information! </h6>
                                                            <el-button v-else type="primary" :disabled="!this.enableSubmit" @click="submitChanges" :loading="loading" class="float-left">SUBMIT</el-button>
                                                        </div>
                                                    </el-form-item>
                                                </section>
                                            </section>
                                            </div>
                                        <div v-else>
                                            <h6 style="color: #983222">Looks like this fundraiser hasn't ended yet. Check back here once the fundraiser has ended. </h6>
                                        </div>
                                    </el-collapse-item>
                                </el-collapse>
                              
                            </el-form>
                        </div>
                    </div>
                </template>
                <hr />
                <div class="row center col-md-10 justify-content-center mt-lg-5 mb-5">
                    <div class="col-md-5">
                        <H2 class="center" style="color: #983222">If you need more help please contact us.</H2>
                        <p class="mt-3 font-weight-medium" style="color: #983222;">EMAIL ADDRESS</p>
                        <p class="mt-2"><a href="mailto:info@coloradojackgiveback.com">info@coloradojackgiveback.com</a></p>
                        <p class="mt-2 font-weight-medium" style="color: #983222">MAILING ADDRESS</p>
                        <p class="mt-2">1702 Schwan Ave NE, Devils Lake, ND 58301</p>
                    </div>
                    <div class="col-md-5 ">
                        <div class="row justify-content-center mt-3 ">
                            <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/normalpopcorn.png" class="img-fluid mb-3" />
                        </div>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import Profile from "./Profile"
    import Address from "./Address"
    //import PasswordAndSecurity from "./PasswordAndSecurity"
    //import IdentityVerification from "./IdentityVerification"
    import axios from "axios"
    import store from "@/store"
    import { mapGetters } from "vuex"

    export default {
        name: 'Settings',
        //components: { Profile, Address, PasswordAndSecurity, IdentityVerification },
        components: { Profile, Address },
        data() {
            return {
                editMode: true,
                submitPayinfo: false,
                loading: false,
                events: {},
                event: {},
                email: '',
                status: '',
                w9Url: "",
                enableSubmit: false,
                event1Rules: {
                    eventPayToAddress: [
                        { required: true, message: 'Check Mailing address is required', trigger: 'blur' }
                    ],
                    eventPayToName: [
                        { required: true, message: 'Check payable to is required', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            selectedevent(val) {
                this.event = val;
                this.event.eventPayOutAmount = this.event.allStoresSubTotal / 2;
                this.event.email = this.email;
                this.event.organizerName = this.currentUserDetail.firstName.concat(" ", this.currentUserDetail.lastName);
                //this.event.eventPaidOut = false;
            },   
            w9Selected(file) {
                this.w9Url = URL.createObjectURL(file.raw);
                this.event.w9file = file;
                if (this.event.w9file != '') {
                    this.enableSubmit = true;
                }
                console.log(this.w9Url);
            },
            handleUploadError() {
                console.log("File upload failed.");
            },
            handleUploadSuccess() {
                console.log("File upload succeeded!");
            },
            validateForm(form) {
                let isValid = false;
                this.$refs[form].validate((valid) => {
                    isValid = valid;
                });
                return isValid;
            },
            submitChanges() {             
                if (this.event.eventPayToAddress != '' && this.event.eventPayToName != '' && this.event.w9file != null) {
                    //var test = this.settings.event;
                    var formData = new FormData();
                    // formData.append("allStoresSubTotal:", this.event.allStoresSubTotal)
                    for (const [key, value] of Object.entries(this.event)) {
                        formData.append(key, value);
                    }

                    //add w9file to formdata
                    if (this.event.w9file != null) {
                        formData.append("w9file", this.event.w9file.raw);
                        //for (const [key, value] of Object.entries(this.event.w9file)) {
                        //    formData.append("w9filedata." + key, value);
                        //}                                   
                    }

                    this.loading = true;
                    axios.put("/api/events/", formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        this.$message({
                            message: 'Changes saved to the payment method.',
                            type: 'success'
                        });
                        this.submitPayinfo = true;
                        this.loading = false;
                    }).catch((error) => {
                        this.loading = false;
                    });
                }
                else {
                    this.$message({
                        message: 'Not all required fields have been filled.',
                        type: 'failure'
                    });
                }
            },
            async getEventDetails() {
                await axios.get('/api/events/').then(response => {
                    this.events = response.data;
                }).catch((error) => {

                });
            },
        },
        computed: {
            tabsPosition() {
                if (screen.width <= 768)
                    return "'top'";
                else
                    return "left";
            },            
            ...mapGetters([
                'isAuthenticated', 'currentUserDetail', 'hasRunningEvent', 'runningEventEndDate'
            ]),
            checkIfUserIsOrganizer() {
                if (this.status) {
                    return this.status.includes("o/e");
                }    
                return false;
            },
        },
        mounted() {
            
        },
        created() {
            this.getEventDetails();           
            this.email = this.currentUserDetail.email;
            this.status = this.currentUserDetail.status;            
        }
    }
</script>


<style scoped>
   
</style>
