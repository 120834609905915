<template>
    <div class="container" :loading="loading">
        <template v-if="store.id!=null">
            <div class="row col-10 pt-4 center">
                <!--<h6><a :href="store.publicUrl">{{store.title}}</a></h6>-->
                <h6 class="center" style="justify-content:center; font-size: .75rem;">Store created by: {{store.storeOwner.firstName}} {{store.storeOwner.lastName}}</h6>
            </div>
            <div class="row col-10 center">
                <h6 class="center" style="justify-content:center; font-size: .75rem;">On behalf of the fundraising event:</h6>
            </div>
            <div class="row col-10 pb-sm-2 center">
                <h6 class="center" style="justify-content:center; font-size: .75rem;">{{store.eventTitle}}</h6>
            </div>
            <div class="pb-3">
                <div class="image-cover">
                    <el-image :src="store.imageSrc" style="border-radius:30px;">
                        <template #error>
                            <div class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image>
                    <div class="img-overlay">
                        <el-image :src="store.eventImageSrc">
                            <template #error>
                                <div class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </template>
                        </el-image>
                    </div>
                </div>
                <div class="row no-gutters">
                    <div class="row col-10 font-weight-bold center mt-1" style="justify-content:center">
                        <h2 class="font-weight-bolder center" style="color: #983222">{{store.title}}</h2>
                    </div>
                    <div class="row col-10 center mb-4">
                        <h5 class="center" style="color: #983222">{{store.description}}</h5>
                    </div>
                    <!--<div class="header-text col-12 col-lg-6 pt-3 elipssis">Overall Event title: {{store.eventTitle}} fundraiser</div>-->
                    <template v-if="store.status=='Open'">
                        <div class="cojackdesc row col-12 center">
                            <div class="header-text pt-2 pb-2 col-12 col-lg-6 row no-gutters">
                                <div class="col-10 font-weight-bold ml-2"><h5 class="font-weight-bold">{{store.storeOwner.firstName}} {{store.storeOwner.lastName}}'s store will be closed in:</h5></div>
                                <div class="col-10 mb-3 ml-5 mr-2">
                                    <EventTimer v-bind:startDate="store.startDate" v-bind:endDate="store.endDate"></EventTimer>
                                </div>
                            </div>
                            <div class="header-text pt-2 pb-2 col-12 col-lg-6 row no-gutters">
                                <div class="col-10 font-weight-bold mb-1 ml-2">
                                    <h5 class="font-weight-bold">Store Sales Attained:</h5>
                                    <h6>${{store.subTotal}} in sales towards goal of ${{store.targetAmount}}</h6>
                                </div>
                                <div class="col-10 mb-3 ml-5 mr-2">
                                    <!--<el-slider v-model="storePercent" range :marks="marks"></el-slider>-->
                                    <el-progress text-inside="true" stroke-width="30" :percentage="storePercentEl" status="success"/>

                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </template>

        <div class="center row align-items-center">
            <template v-if="store.status=='Closed'">
                <div class="col-12 text-center"><i class="el-icon-lock" style="font-size:10rem"></i></div>
                <div class="col-12 text-center mt-5 h2 font-weight-bold">CLOSED</div>
            </template>
            <template v-else-if="store.status=='Draft'">
                <div class="col-12 text-center"><i class="el-icon-time" style="font-size:10rem"></i></div>
                <div class="col-12 text-center mt-2 h2 font-weight-bold">OPENING SOON</div>
                <div class="col-12 text-center mt-2 h2 font-weight-bold">{{new Date(store.startDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</div>
            </template>
            <template v-else-if="!store.status && !loading">
                <div class="col-12 text-center"><i class="el-icon-warning" style="font-size:10rem"></i></div>
                <div class="col-12 text-center mt-5 h3 font-weight-bold">STORE DOES NOT EXIST</div>
            </template>
        </div>

        <div class="row mt-2">
            <div class="col-12 col-md-6" v-for="product in store.products" :key="product.id">
                <div v-if="store.status=='Open'">
                    <Product :product="product" @click="showProduct(product)"></Product>
                </div>
                <div v-else>
                    <Product :product="product" @click="closedProduct(product)"></Product>
                </div>
            </div>
        </div>

        <div class="leaders row no-gutters" style="justify-content:center;" v-if="!loading">
            <div class="col-12 row no-gutters" style="justify-content:center">
                <h5 class="" style="text-align: center; padding-top:1rem;">50% of each bag you purchase will be donated back to the fundraiser!</h5>
            </div>
            <div class="mt-1 mb-1 col-12 row no-gutters" style="border-radius: 10px; justify-content: center">
                <hr class="row col-12" style="color:red" />
                <h5 class="col-12 row no-gutters" style="justify-content: center; color: #9A3324">Fundraiser Top Sellers:</h5>
                <ul class="infinite-list row col-12" style="justify-content:center; border-radius:10px">
                    <li class="infinite-list-item row col-12 col-md-12" v-for="(storeleader,index) in leaderstores" :key="storeleader.id">
                        <div class="col-2"><h5>{{index + 1}}</h5></div>
                        <div class="col-2">
                            <el-image :src="storeleader.imageSrc" class="smallimg-overlay" style="border-radius: 10px; max-height: 40px; max-width: 40px"></el-image>
                        </div>
                        <div class="col-6"><a :href="store.publicUrl" target="_blank" class="center"><h6 class="center" style="justify-content: center; color: #9A3324">{{storeleader.storeOwner.name}}</h6></a></div>
                        <div class="col-2"><h5>${{storeleader.subTotal}}</h5></div>
                       <!-- <div class="col-2" v-if="storeleader.subTotal>'0'"><h5>${{storeleader.subTotal}}</h5></div>-->
                    </li>
                </ul>
            </div>
        </div>

    </div>
    <ProductDetail ref="productDetail" ></ProductDetail>
</template>

<script>
    import axios from "axios"
    import Product from "./Product.vue";
    import ProductDetail from "./ProductDetail.vue";
    import store from "@/store"
    import EventTimer from "./EventTimer.vue";
    import { initFacebookSdk, } from '@/services';
    import { reactive } from 'vue';
    import { ElMessage, ElMessageBox } from 'element-plus';

    export default {
        name: "PublicStore",
        components: { Product, ProductDetail, EventTimer },
        data() {
            return {
                store: { products: [] },
                leaderstores: [],
                currentProduct: {},
                loading: false,
                storeState: true
            }
        },
        computed: {
            storePercentEl() {
                const percentage1 = Math.trunc(((this.store.subTotal) / this.store.targetAmount) * 100);

                return percentage1;
            },
            storePercent() {
                const percentage = Math.trunc(((this.store.subTotal) / this.store.targetAmount) * 100);
               
                return [0, percentage];
            },
            marks() {
                const test = Math.trunc(((this.store.subTotal) / this.store.targetAmount) * 100);
                //this.value = [0, test];
                const marks = reactive({
                    0: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '0%',
                    },
                    20: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '20%',
                    },
                    40: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '40%',
                    },
                    60: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '60%',
                    },
                    80: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '80%',
                    },
                    100: {
                        style: {
                            color: '#1989FA',
                        },
                        label: '100%',
                    },
                })
                return marks;
            },          
        },
        created() {
            initFacebookSdk();
            //get store detail
            //save store
            store.dispatch("setStoreCode", this.$route.params.code);
            this.loading = true;
            axios.get("/api/stores/public/" + this.$route.params.code).then(response => {
                this.loading = false;
                this.store = response.data;

                axios.get('/api/stores/getPublicStoresByEvent/' + response.data.eventCode).then(response => {
                    this.leaderstores = response.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            }).catch((error) => {
                this.loading = false;
            });            

        },
        methods: {
            showProduct(product) {
                if (this.store.status != 'Open') {
                    this.storeState = false;
                }

                this.currentProduct = product;
                this.$refs.productDetail.showProduct(this.currentProduct.id);
            },  
            closedProduct(product) {
                if (this.store.status == "Draft") {
                    let opendate = new Date(this.store.startDate).toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });
                    ElMessageBox.alert('This store will be opening soon! ' + opendate, 'Store coming soon!', {
                        // if you want to disable its autofocus
                        // autofocus: false,
                        confirmButtonText: 'OK',
                    })
                }

                if (this.store.status == "Closed") {
                    ElMessageBox.alert('This fundraiser has ended and the store has been closed!', 'Store closed!', {
                        // if you want to disable its autofocus
                        // autofocus: false,
                        confirmButtonText: 'OK',
                    })
                }                
            }
        }
    }
</script>

<style scoped>

    .cojackdesc {
        position: relative;
        border: 1px solid #ccc;
        border-radius: 10px;        
    }

    .container{
        padding-right:1px;
        padding-left:1px;
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 350px) {
        p {
            font-size: 1.0rem;
        }

        h1 {
            font-size: 1.5rem;
        }

        h2 {
            font-size: 1.2rem;
        }

        h5 {
            font-size: 1.0rem;
        }

        h6 {
            font-size: .80rem;
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 544px) {
        p {
            font-size: 1.0rem;
        }

        h1 {
            font-size: 1.5rem;
        }

        h2 {
            font-size: 1.25rem;
        }

        h5 {
            font-size: 1.00rem;
        }

        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {
        p {
            font-size: 1.0rem;
        }

        h1 {
            font-size: 2.0rem;
        }

        h2 {
            font-size: 1.75rem;
        }

        h5 {
            font-size: 1.25rem;
        }

        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        p {
            font-size: 1.25rem;
        }

        h1 {
            font-size: 2.5rem;
        }

        h2 {
            font-size: 2.0rem;
        }

        h5 {
            font-size: 1.5rem;
        }

        h6 {
            font-size: 1.25rem;
        }
        /*1rem = 16px*/
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        p {
            font-size: 1.50rem;
        }

        h1 {
            font-size: 3.0rem;
        }

        h2 {
            font-size: 2.5rem;
        }

        h5 {
            font-size: 2.0rem;
        }

        h6 {
            font-size: 1.60rem;
        }
        /*1rem = 16px*/
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .text {
        font-size: 10px;
    }

    .item {
        margin-bottom: 10px;
    }

    .infinite-list {
        /*height: 250px;*/
        padding: 0px;
        margin: 10px;
        list-style: none;
        border-radius:10px;
    }

    .infinite-list .infinite-list-item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        background: #f2f2f2;
        margin: 2px;
       /* border: solid #ccc;
        border-radius: 1px;*/
        /* color: var(--el-color-primary);*/
    }

    .infinite-list .infinite-list-item + .list-item {
        margin-top: 12px;
    }

    .smallimg-overlay {
        border: 1px solid #fff;
        border-radius: 100%;
        overflow: hidden;
    }

    .leaders {
        position: relative;
        border: 1px solid #ccc;
        border-radius: 10px;
    }
       

</style>