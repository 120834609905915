<template>
    <div></div>
</template>

<script>

    
    export default {
        name: "PublicEvent",
        components: { },
        data() {
            return {
               
            }
        }
        }
</script>

<style scoped>
    
</style>