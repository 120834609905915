<template>
    <div class="container" :loading="loading">
        <div class="row pt-5" style="justify-content:center" v-if="!editMode">
            <h5 style="color: #983222">Fundraiser Details:</h5>
        </div>
        <!--<div class="row no-gutters" v-if="!editMode">
            <div class="h4 font-weight-medium pt-2 pb-2 p-sm-0 pb-sm-3 elipssis center" v-if="event.storeId!=null"><router-link :to="{ name: 'OpenStore',params: { id: event.storeId }}" style="color: #9A3324">{{eventEdit.title}}</router-link></div>
        </div>-->
        <div class="row no-gutters" v-if="!editMode">
            <div class="center"><h6 style="">Fundraiser default image - you can edit this image by choosing "Edit Fundraiser" button below.  Participants will choose there own image for there online store, but the default fundraiser image will be displayed in a circle in the upper right hand corner of each participants store.</h6></div>
        </div>
        <div class="row no-gutters">
            <div class="col image-cover">
                <el-image :src="event.imageSrc" style="border-radius:30px" v-if="!editMode">
                    <template #error>
                        <div class="image-slot">
                            <i class="el-icon-picture-outline"></i>
                        </div>
                    </template>
                </el-image>
                <ImageUploader @crop="setCropData" @image-selected="setSelectedImage" v-if="editMode" :image="event.imageSrc"></ImageUploader>
            </div>
        </div>
        <div class="row pt-3 pb-3" style="justify-content:center; text-align:center">
            <div class="header-text col-10 col-lg-6 mb-1 mb-lg-0 mt-5">
                <div class="row no-gutters">
                    <div class="col-4"><h5 style="color: #983222">Time Remaining:</h5></div>
                    <div class="col-8"><h5 style="color: #983222"><EventTimer v-bind:startDate="event.startDate" v-bind:endDate="event.endDate" v-if="event.endDate!=null"></EventTimer></h5></div>
                </div>
            </div>
        </div>
        <div class="row no-gutters mt-lg-5" v-if="editMode">
            <div class="col-12">
                <el-form :model="eventEdit" :rules="eventEditRules" ref="eventEditForm" :label-position="top" hide-required-asterisk="true">
                    <el-form-item label="TITLE" prop="title">
                        <el-input v-model="eventEdit.title" v-fancylabel v-on:keyup.enter="saveChanges"></el-input>
                    </el-form-item>
                    <el-form-item label="DESCRIPTION" prop="description">
                        <el-input v-model="eventEdit.description" v-fancylabel v-on:keyup.enter="saveChanges"></el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <label class="top-label">Event Information</label>
                <div class="title">
                    <div style="overflow: hidden;">
                        <div class="row">
                            <div class="col-4"><h6>Edit Fundraiser:</h6></div>
                            <div class="col-8">
                                <el-button-group>
                                    <!--<el-button type="primary" size="small" icon="el-icon-share" @click="invDonatorVisible=true" title="Share your fundraising Store via Email!">Share with email</el-button>-->
                                    <!-- <a class="share-twitter el-button el-button--small" @click="shareOnTwitter"><i class="fab fa-twitter" title="Share your Fundraising Store on Twitter"></i> Share on Twitter</a>-->
                                    <!--<a class="share-facebook el-button el-button--small" @click="shareOnFacebook"><i class="fab fa-facebook-f" title="Share your Fundraising Store on Facebook"></i> Share On Facebook</a>
                                <a class="share-facebook el-button el-button--small" @click="sendOnFacebook"><i class="fab fa-facebook-f" title="Share your Fundraising Store on Facebook with specific people"></i> Send to Specific people</a>-->
                                    <el-button style="width:150px" class="mr-2" type="primary" size="small" icon="el-icon-edit-outline" @click="editMode=true" v-if="!editMode" title="Edit Fundraiser, this button allows you to make changes to Fundraiser title, Description and Event Picture!">Edit Fundraiser</el-button>
                                    <el-button style="width:150px" class="mr-2" type="primary" size="small" icon="el-icon-check" :loading="loading" @click="saveChanges" v-if="editMode" title="Save Changes to Store!">Save Changes</el-button>
                                    <el-button type="danger" style="background-color: #983222; width:150px" size="small" icon="el-icon-remove" @click="confirmStopEvent" title="Warning: this button removes the Fundraiser!">Delete Fundraiser</el-button>
                                </el-button-group>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-4 "><h6>Fundraiser Title:</h6></div>
                            <div class="col-8 text-left"><h6>{{eventEdit.title}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 "><h6>Fundraiser Description:</h6></div>
                            <div class="col-8 text-left"><h6>{{eventEdit.description}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4 "><h6>Store Details Page:</h6></div>
                            <div class="col-8 text-left" v-if="event.storeId!=null"><h6><router-link style="color: #9A3324" :to="{ name: 'OpenStore',params: { id: event.storeId }}">{{eventEdit.title}}</router-link></h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4"><h6>Organizer Email:</h6></div>
                            <h6 class="col-8 text-left">{{profile.email}}</h6>
                        </div>
                        <hr />

                        <div class="row">
                            <div class="col-4"><h6>START DATE:</h6></div>
                            <div class="col-8 text-left"><h6>{{new Date(event.startDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</h6></div>
                        </div>
                        <div class="row">
                            <div class="col-4"><h6>END DATE:</h6></div>
                            <div class="col-8 text-left"><h6>{{new Date(event.endDate).toLocaleDateString("en-US",{  year: 'numeric', month: 'long', day: 'numeric' })}}</h6></div>
                        </div>
                        <hr />
                        <div class="statistics">
                            <div class="row">
                                <div class="col-4"><h6>Fundraiser Target:</h6></div>
                                <div class="col-8 text-left"><h6>${{parseFloat(event.targetAmount).toFixed(2)}}</h6></div>
                            </div>
                            <!--<div class="row">
    <div class="col-4"><h6>Total Collected:</h6></div>
    <div class="col-8 text-left"><h6>${{event.allStoresSubTotal}}</h6></div>
    </div>
    <div class="row">
        <div class="col-4"><h6>Orgainizer Store Total:</h6></div>
        <div class="col-8 text-left"><h6>${{event.organizerStoreTotal}}</h6></div>
    </div>-->
                            <div class="row">
                                <div class="col-4"><h6>Total Sales:</h6></div>
                                <div class="col-8 text-left"><h6>${{parseFloat(event.allStoresSubTotal).toFixed(2)}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6>Total Earned:</h6></div>
                                <div class="col-8 text-left mt-0 mb-0">
                                    <el-button style="color: #983222;padding-top:0px; padding-bottom:0px;" class="ml-0 mt-0 mb-0" type="text" size="small" @click="eventBreakDown=true" title="Click to view fundraiser dollar breakdown"><h6>${{parseFloat(Math.round((event.allStoresSubTotal * 100) / 100) / 2).toFixed(2)}}</h6></el-button>
                                </div>
                                <!--  <div class="col-8 text-left"><h6>${{Math.round((event.organizerStoreSubTotal * 100) / 100) / 2}}</h6></div>-->
                            </div>
                            <div class="row">
                                <div class="col-4"><h6>Number of Participants:</h6></div>
                                <div class="col-8 text-left"><h6>{{event.participantsCount}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6>Number of Donations:</h6></div>
                                <div class="col-8 text-left"><h6>{{event.donationsCount}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4"><h6>Master Invite Code:</h6></div>
                                <div class="col-8 text-left"><h6 style="color: #983222">{{event.code}}</h6></div>
                            </div>
                            <div class="row">
                                <div class="col-4 font-weight-normal"><h6>Master invite Link</h6></div>
                                <el-popover placement="top"
                                            :width="650"
                                            auto-close="4000"
                                            :trigger="click"
                                            effect="dark"
                                            content="Copied participant invite url, you can now paste the link into an Email or SMS message!">
                                    <template #reference>
                                        <h6 class="col-7 el-icon-copy-document c-pointer h6" style="color: #9A3324" title="Copied participant invite url to your clipboard, then you can paste into an email or text message" id="eventCode" :data-copy="masterInviteUrl" @click="copyCode"> MasterUrlInviteCode</h6>
                                    </template>
                                </el-popover>
                            </div>
                            <div class="row" v-if="isMobile">
                                <div class="col-4"><h6>Invite Sellers with your phones SMS:</h6></div>
                                <div class="col-8 text-left">
                                    <a style="background-color:aquamarine" class="el-button el-button--large mr-2" v-bind:href="smsUrl" v-if="isMobile"><i class="fas fa-sms" style='color: white'></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <label class="top-label">SELLER INVITE STATUS</label>
                <el-badge :value="event.participantsCount" v-if="parseInt(event.participantsCount)>0" class="count" title="This is the status of the email invitation that was sent to the sellers!"></el-badge>

                <div class="participants">
                    <div class="row col-10 center" style="text-align:left">
                        <ul>
                            <li><h6><el-button type="info" @click="invPartInfoVisible=true" icon="el-icon-info" circle title="more info"></el-button> To get started, please invite Sellers(team members) to create their own fully functional eCommerce store front! Just click the 'Invite Sellers With Email/SMS' buttons below and enter their email address or phone number. The email and sms messages are sent from our servers.</h6></li>
                            <li><h6>It should only take a few minutes for the Seller to setup their site, once they start the setup.</h6></li>
                            <li><h6>As you invite more sellers, it allows your fundraising event to reach more potential buyers!</h6></li>
                            <li>
                                <h6>
                                    If you would like to send the email or sms out to the sellers yourself, and bypass our servers, use the copy seller invite url:
                                    <el-popover placement="top"
                                                :width="650"
                                                auto-close="4000"
                                                :trigger="click"
                                                effect="dark"
                                                content="Copied seller invite url, you can now paste the link into an Email or SMS message!">
                                        <template #reference>
                                            <h6 class="el-icon-copy-document c-pointer h6" style="color: #983222" title="Copied participant invite url to your clipboard, then you can paste into an email or text message" id="eventCode" :data-copy="masterInviteUrl" @click="copyCode"> MasterUrlInviteCode</h6>
                                        </template>
                                    </el-popover>
                                </h6>
                            </li>
                            <li><h6 style="">Also you can send the Master Invite Code - {{event.code}} directly to your participants they can use this in the mobile app to join your fundraiser or to join through the website.</h6></li>
                        </ul>
                    </div>
                    <hr />
                    <div class="row col-12 center" style="justify-content:center">
                        <div class="col-sm-6" style="text-align:center">
                            <el-button size="medium" style="background-color: #983222; justify-content:center; width:230px" type="danger" @click="invPartVisible=true" title="Invite others to participate/sell in the Fundraiser, they will create their own unique eCommerce store, under your Fundraiser!">INVITE SELLERS WITH EMAIL</el-button>
                        </div>
                        <div class="col-sm-6" style="text-align:center">
                            <el-button size="medium" style="background-color: #008000; justify-content: center; width: 230px" type="primary" @click="invPartVisibleSMS=true" title="Invite others to participate/sell in the Fundraiser, they will create their own unique eCommerce store, under your Fundraiser!">INVITE SELLERS WITH SMS</el-button>
                        </div>
                    </div>
                    <hr />
                    <div class="row no-gutters">
                        <div class="col-4 text-left"><h6>Email/Phone:</h6></div>
                        <div class="col-2 text-right"><h6>Status:</h6></div>
                        <div class="col-2 text-right"><h6>Invite Date:</h6></div>
                        <div class="col-4 text-right"><h6>Resend Invite:</h6></div>
                    </div>
                    <hr class="m-1" />
                    <div style="overflow: auto; height: 300px; ">
                        <div class="row pt-1 pb-1 no-gutters" v-for="participanttest in invitedParticipants2" :key="participanttest.status">
                            <!-- <div class="col-4 elipssis pl-0" v-if="participanttest.storeCode!=null"><a :href="store.publicUrl" target="_blank" class="center"><h6 class="center" style="justify-content: center; color: #9A3324">{{participanttest.email ?? participanttest.phone}}</h6></a></div>-->
                            <div class="col-4 elipssis pl-0"><h6>{{participanttest.email ?? participanttest.phone}}</h6></div>
                            <div class="col-2 text-right"><h6>{{participanttest.inviteStatus}}</h6></div>
                            <div class="col-2 text-right"><h6>{{(new Date(participanttest.createDate)).toLocaleDateString("en-US")}}</h6></div>
                            <div class="col-4">
                                <div class="row col-12 center" style="justify-content:right">
                                    <el-button size="small" v-if="participanttest.inviteStatus=='Invited'" style="background-color: #983222;" type="danger" :loading="loading" @click="resendInvite(participanttest)" title="Resend invite to participant!">RESEND</el-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row col-10 center">
                        <h6>If you have many participants to invite, you can upload a comma delimted .csv or .txt file that contains emails that you would like to invite to participate in the Event! The format example: email1@example.com,email2@example.com with no space between the comma and email address.</h6>
                    </div>
                    <div class="row col-10 center mb-5" style="justify-content:center; align-content:center">
                        <FileUploader @file-selected="setSelectedFile" :file="invitedParticipant.file" class="center" style="justify-content:center; align-content:center"></FileUploader>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="parseInt(event.participantsCount)>0">
                <label class="top-label">TOP ACTIVE SELLERS</label>
                <!--<el-badge :value="event.participantsCount" v-if="parseInt(event.participantsCount)>0" class="count" title="This is the number of people selling on behalf of the Event that have thier own unique online store!">
            </el-badge>-->
                <div class="sellers">
                    <div class="row no-gutters">
                        <div class="col-4 mb-3 ml-2"></div>
                        <div class="col-3 text-right"><h6>STORE GOAL</h6></div>
                        <div class="col-2 text-right"><h6>SALES</h6></div>
                        <div class="col-2 text-right"><h6>AMOUNT EARNED</h6></div>
                    </div>
                    <hr class="m-1" />
                    <div style="">
                        <div class="row pt-1 pb-1 no-gutters" v-for="participant in participants" :key="participant.name">
                            <div class="header-text pt-2 pb-2 col-12 row no-gutters">
                                <div class="col-12 font-weight-medium mb-1 ml-2"><h6 class="font-weight-medium">{{participant.name}} - Store Goal Attained:</h6></div>

                                <div class="col-4 mb-3 ml-2">
                                    <el-progress text-inside="true" stroke-width="25" :percentage="Math.trunc(((participant.amount) / participant.targetAmount) * 100)" />
                                </div>
                                <div class="col-3 text-right"><h6>${{participant.targetAmount}}</h6></div>
                                <div class="col-2 text-right"><h6>${{participant.amount}}</h6></div>
                                <div class="col-2 text-right"><h6>${{participant.amount / 2}}</h6></div>
                            </div>
                        </div>
                        <hr />
                        <div class="row no-gutters">
                            <div class="col-4 mb-3 ml-2"><h6>TOTALS:</h6></div>
                            <div class="col-3 text-right"><h6></h6></div>
                            <div class="col-2 text-right"><h6>${{parseFloat(event.allStoresSubTotal).toFixed(2)}}</h6></div>
                            <div class="col-2 text-right"><h6>${{parseFloat(Math.round((event.allStoresSubTotal * 100) / 100) / 2).toFixed(2)}}</h6></div>
                        </div>
                    </div>
                </div>
            </div>

            <el-dialog title="TOTAL PROFITS" style="font-weight:normal" :width="dialogWidth" :top="dialogTop" v-model="eventBreakDown">
                <div class="">
                    <el-form :model="calculatedTotals" show-message="false" @submit.prevent>
                        <div class="row no-gutters pb-2 h5">
                            <div class="col-12">
                                <div class="word-line-break mt-3"><span>BREAKDOWN</span></div>
                                <div class="row">
                                    <div class="col-9"><h6>Total Sales</h6></div>
                                    <div class="col-3 text-left"><h6>${{parseFloat(event.allStoresSubTotal).toFixed(2)}}</h6></div>
                                </div>
                                <hr />
                                <div class="row">
                                    <div class="col-9"><h6>Total Earned Profits(50% of Fundraiser Subtotal):</h6></div>
                                    <div class="col-3 text-left"><h6 style="color: green">${{parseFloat(event.allStoresSubTotal / 2).toFixed(2)}}</h6></div>
                                </div>
                                <div class="word-line-break mt-5"><span>FUNDRAISER SALES - All BUYERS</span></div>
                                <div class="donators">
                                    <el-table :data="donatorsOrders" style="width:100%" height="350">
                                        <el-table-column prop="orderdate" label="ORDER DATE" width="150" />
                                        <el-table-column prop="ordernum" label="ORDER NUMBER" width="150" />
                                        <el-table-column prop="name" label="BUYER NAME" width="150" />
                                        <el-table-column prop="amount" label="AMOUNT" width="150" />
                                        <el-table-column prop="participantname" label="SELLER NAME" width="150" />
                                    </el-table>
                                </div>
                            </div>
                        </div>
                    </el-form>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="eventBreakDown=false">Close</el-button>
                    </span>
                </template>
            </el-dialog>

            <el-dialog title="Example Seller Invite Email" :width="dialogWidth" :top="dialogTop" v-model="invPartInfoVisible">
                <div class="" style="overflow:auto">
                    <div class="row no-gutters pb-2">
                        <div class="col-12 text-break">
                            This is an example of the email the seller will recieve to sign up for the Fundraiser.
                        </div>
                    </div>
                    <div class="row no-gutters pb-2">
                        <div class="col-12 text-break">
                            The seller will click on the Join Fundraiser button to start setting up their account and fundraiser store.
                        </div>
                    </div>
                    <div class="row no-gutters pb-2">
                        <div class="col-12 text-break">
                            The email will be sent from info@coloradojackgiveback.com.
                        </div>
                    </div>
                    <div class="row no-gutters pb-2 h5">
                        <img src="https://coloradojackgivebacksa.blob.core.windows.net/vuepublicpage/participantInvite.jpg" class="img-fluid center" style="max-width:100%; height:auto" />
                    </div>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button type="danger" style="background-color: #983222" @click="invPartInfoVisible=false" :loading="loading">Ok</el-button>
                    </span>
                </template>
            </el-dialog>

            <el-dialog title="INVITE SELLERS WITH EMAIL" :width="dialogWidth" :top="dialogTop" v-model="invPartVisible">
                <div class="">
                    <el-alert v-show="emailErrorMessage.length > 0" :title="emailErrorMessage" :closable="false"
                              type="error"
                              effect="dark">
                    </el-alert>
                </div>
                <div class="" style="overflow:auto">
                    <el-form :model="invitedParticipant" :rules="emailValidRules" ref="emailValid" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>

                        <!--<form :rules="emailValidRules">-->
                        <div class="row no-gutters pb-2">
                            <div class="col-12 text-break">
                                Invite others to Sell on behalf of your Fundraiser via an Email address.  Enter their email address below and they will recieve an email to signup for this Fundraiser.
                            </div>
                        </div>
                        <div class="row no-gutters pb-2 h5">
                            <div class="col-12">
                                EMAIL
                            </div>
                        </div>
                        <el-form-item label="EMAIL" prop="email">
                            <!--<el-input type="email" v-model="invitedParticipant.email" autocomplete="on" v-fancylabel v-on:keyup.enter="addEmailField"></el-input>-->
                            <el-input type="email" v-model="invitedParticipant.email" autocomplete="on" v-fancylabel></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="invPartVisible=false">Cancel</el-button>
                        <el-button type="danger" style="background-color: #983222" @click="inviteParticipants" :loading="loading">INVITE</el-button>
                    </span>
                </template>
            </el-dialog>

            <el-dialog title="INVITE SELLERS WITH SMS" :width="dialogWidth" :top="dialogTop" v-model="invPartVisibleSMS">
                <div class="">
                    <el-alert v-show="emailErrorMessage.length > 0" :title="emailErrorMessage" :closable="false"
                              type="error"
                              effect="dark">
                    </el-alert>
                </div>
                <div class="" style="overflow:auto">
                    <el-form :model="invitedParticipant" :rules="phoneValidRules" ref="phoneValid" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>

                        <!--<form :rules="emailValidRules">-->
                        <div class="row no-gutters pb-2">
                            <div class="col-12 text-break">
                                Invite Sellers to your Fundraiser via an SMS text message.  Enter the Sellers phone number below and they will recieve an sms from our server number(701-929-7768) with a link to signup for this Fundraiser.
                            </div>
                        </div>
                        <div class="row no-gutters pb-2 h5">
                            <div class="col-12">
                                Phone Number
                            </div>
                        </div>
                        <el-form-item label="Phone" prop="phone">
                            <!--<el-input type="email" v-model="invitedParticipant.email" autocomplete="on" v-fancylabel v-on:keyup.enter="addEmailField"></el-input>-->
                            <el-input type="string" v-model="invitedParticipant.phone" autocomplete="on" v-fancylabel></el-input>
                        </el-form-item>
                    </el-form>
                </div>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="invPartVisibleSMS=false">Cancel</el-button>
                        <el-button type="danger" style="background-color: green" @click="inviteParticipantsSMS" :loading="loading">INVITE</el-button>
                    </span>
                </template>
            </el-dialog>


            <el-dialog title="INVITE BUYERS TO STORE WITH EMAIL" :width="dialogWidth" :top="dialogTop" v-model="invDonatorVisible">
                <div class="" style="overflow:auto">
                    <form novalidate>
                        <div class="row no-gutters pb-2">
                            <div class="col-12 text-break">
                                Invite Buyers to Donate to your Fundraiser via Email.  Enter their email address below and they will recieve an email which will include a direct link to your Fundraising Store.
                            </div>
                        </div>
                        <div class="row no-gutters pb-2 h5">
                            <div class="col-12">
                                EMAIL
                            </div>
                        </div>
                        <div class="row no-gutters align-items-center pb-2" v-for="donator in invitedDonators" :key="donator.counter">
                            <div class="col-9">
                                <el-input type="text" size="medium" @input="addInvitedDonator" v-model="donator.email"></el-input>
                            </div>
                            <div class="col-1"></div>
                            <div class="col-2" v-if="donator.counter > 0">
                                <el-button type="danger" style="background-color: #983222" icon="el-icon-close" circle @click="removeDonator(donator)"></el-button>
                            </div>
                        </div>
                    </form>
                </div>

                <template #footer>
                    <span class="dialog-footer">
                        <el-button @click="invDonatorVisible=false">Cancel</el-button>
                        <el-button type="danger" style="background-color: #983222" @click="inviteDonators" :loading="loading">INVITE</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>

        <label class="top-label">Fundriaser Charts</label>
        <div class="participants">
            <div class="row">
                <div class="col-12 col-lg-4 pl-0 center" style="text-align:center">
                    <label class="font-weight-medium" style="justify-content:center">Fundraiser Goal chart:</label>
                    <DoughnutChart v-bind:chartData="eventChartData" v-bind:chartOptions="chartOptions" :key="eventChartData.labels" />
                </div>
                <div class="col-12 col-lg-4 pl-0 center" style="text-align:center" v-if="parseInt(event.participantsCount)>0">
                    <label class="font-weight-medium" style="justify-content:center">Seller totals chart:</label>
                    <PieChart v-bind:chartData="participantsChartData" v-bind:chartOptions="chartOptions" :key="participantsChartData.labels" />
                </div>
                <div class="col-12 col-lg-4 pl-0 center" style="text-align:center" v-if="parseInt(event.donationsCount)>0">
                    <label class="font-weight-medium" style="justify-content:center">Donator totals chart:</label>
                    <PieChart v-bind:chartData="donatorsChartData" v-bind:chartOptions="chartOptions" :key="donatorsChartData.labels" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import axios from "axios"
    import EventTimer from "./EventTimer.vue";
    import { copyToClipboard } from "../assets/js/app.js";
    import PieChart from './PieChart.vue'
    import DoughnutChart from './DoughnutChart.vue'
    import ImageUploader from "./ImageUploader"
    import ChartDataLabels from 'chartjs-plugin-datalabels';
    import router from "../router";
    import { mapGetters } from "vuex"
    import { initFacebookSdk, } from '@/services';
    import _ from "lodash";        
    import FileUploader from "./FileUploader"
    
    export default {
        name: "RunningEvent",
       // components: { EventTimer, PieChart, DoughnutChart, ImageUploader },
        components: { EventTimer, PieChart, DoughnutChart, ImageUploader, FileUploader },
        data() {
            return {
                smsUrl: '',
                isMobile: false,
                calculatedTotals: {
                    totalSales: 0,
                    eventSubtotal: 0,
                    totalElgibleProfit: 0,
                    shippingCosts:0,
                    adminFee:0,
                },
                emailErrorMessage: '',
                invPartVisible: false,
                invPartVisibleSMS: false,
                invPartInfoVisible: false,
                eventBreakDown: false,
                invitedParticipants: [{ counter: 0, email: '' }],
                invitedParticipants2: [{ email: '', phone: '', invitestatus: '', organizername: '', createdate: '', storecode: ''}],
                invitedParticipant: {
                    email: '',
                    phone: '',
                    invitestatus: '',
                    organizername: '',
                    createdate: '',
                    file:''
                },
                masterInviteUrl: '',
                csvEmails: null,
                file: null,
                invDonatorVisible: false,
                invitedDonators: [{ counter: 0, email: '' }],
                event: {},
                eventEdit: {
                    imageCropData: {}
                },                
                loading: true,
                editMode: false,
                eventEditRules: {
                    title: [
                        { required: true, message: 'Title is required', trigger: 'change' }
                    ],
                    description: [
                        { required: true, message: 'Description reason is required', trigger: 'change' }
                    ]
                },
                emailValidRules: {
                    email: [
                        { required: true, type: 'email', message: 'Invalid email address', trigger: 'blur' }
                    ]
                },
                phoneValidRules: {
                    phone: [
                        { required: true, type: 'string', pattern: /^\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/g, message: 'A valid Phone Number is required', trigger: 'blur' }
                    ],
                },
                profile: {
                    email: '',
                    firstName: '',
                    lastName: '',
                    imageSrc: '',
                    imageCropData: {}
                },
                chartOptions: {
                    responsive: true,
                    animation: {
                        duration:0
                    },
                    hover: {
                        animationDiration:0
                    },
                    responsiveAnimationDuration:0,
                    plugins: {
                        ChartDataLabels:{
                            labels: [
                                {
                                    render: 'label',
                                    position: 'outside'
                                },
                                {
                                    render: 'percentage'
                                }
                            ]
                        }
                    }
                }
            }
        },
        computed: {
            storePercentEl(subtotal, targetamount) {
                const percentage1 = Math.trunc(((subtotal / 2) / targetamount) * 100);

                return percentage1;
            },
            participants() {
                let ps = [];
                if (this.event.participants != null) {
                    this.event.participants.split(',').forEach(p => {
                        let items = p.split('_');
                        ps.push({ name: items[0], targetAmount: items[1], amount: parseInt(items[2]) });
                    });
                }
                //let ps1 = ps.sort((a, b) => (a.amount > b.amount) ? 1 : -1);      
                let test = _.orderBy(ps, 'amount','desc');
                
                return test;
            },
            donators() {
                let dn = [];
                if (this.event.donators != null) {
                    this.event.donators.split(',').forEach(p => {
                        let items = p.split('_');
                        dn.push({ name: items[0], amount: items[1] });
                    });
                }               
                return dn;
            },
            donatorsOrders() {
                let dn = [];
                if (this.event.donatorsOrders != null) {
                    this.event.donatorsOrders.split(',').forEach(p => {
                        let items = p.split('_');
                        dn.push({ name: items[0], amount: items[1], ordernum: items[2], orderdate: items[3], participantname: items[4] });
                    });
                }
                return dn;
            },
            eventChartData() {
                let datatemplate = {
                    labels: [], datasets: [{ label:'Fundraiser Totals', 
                        data: [], backgroundColor: []
                    }]
                };
                if (this.event != null) {
                    let percentage = ((this.event.allStoresSubTotal * 100) / this.event.targetAmount);
                    datatemplate.labels.push('Event Goal: $' + parseFloat(this.event.targetAmount).toFixed(2));
                    datatemplate.datasets[0].data.push(100 - Math.round(percentage));
                    datatemplate.datasets[0].backgroundColor.push('#E46651');

                    datatemplate.labels.push('Elgible Payout Amount: $' + parseFloat(this.event.allStoresSubTotal / 2).toFixed(2));
                    datatemplate.datasets[0].data.push(Math.round(percentage));
                    datatemplate.datasets[0].backgroundColor.push('#41B883');
                }
                return datatemplate;
            },
            participantsChartData() {
                let datatemplate = {
                    labels: [], datasets: [{
                        data: [], backgroundColor: [] }]
                };
                if (this.event.participants != null) {
                    this.event.participants.split(',').forEach(p => {
                        if (p != null) {
                            let items = p.split('_');
                            if (items != null) {
                                var letters = '0123456789ABCDEF';
                                var color = '#';
                                for (var i = 0; i < 6; i++) {
                                    color += letters[Math.floor(Math.random() * 16)];
                                }
                                datatemplate.labels.push(items[0].trim());
                                datatemplate.datasets[0].data.push(items[2]);
                                datatemplate.datasets[0].backgroundColor.push(color);
                            }
                        }
                    });
                }
                return datatemplate;                                
            },
            donatorsChartData() {
                let datatemplate = {
                    labels: [], datasets: [{
                        data: [], backgroundColor: []
                    }]
                };
                if (this.event.donators != null) {
                    this.event.donators.split(',').forEach(p => {
                        if (p != null) {
                            let items = p.split('_');
                            if (items != null) {
                                var letters = '0123456789ABCDEF';
                                var color = '#';
                                for (var i = 0; i < 6; i++) {
                                    color += letters[Math.floor(Math.random() * 16)];
                                }
                                datatemplate.labels.push(items[0].trim());
                                datatemplate.datasets[0].data.push(items[1].toString());
                                datatemplate.datasets[0].backgroundColor.push(color);
                            }
                        }
                    });
                }

                return datatemplate;
            },
            ...mapGetters([
                'currentUserDetail'
            ]),
            dialogWidth() {
                if (screen.width <= 576)
                    return "100%";
                else return "600px";
            },
            dialogTop() {
                if (screen.width <= 576)
                    return "0";
                else return "15vh";
            }
        },
        created() {
            axios.get('/api/events/running/').then(response => {
                this.loading = false;
                this.event = response.data;
                this.event.storeLink = "/store/open/" + this.event.storeId;
                this.eventEdit.id = response.data.id;
                this.eventEdit.title = this.event.title;
                this.eventEdit.description = this.event.description;
                this.calcTotals();

                axios.get('/api/participants/getparticipants?id=' + response.data.id).then(response => {
                    this.invitedParticipants2 = response.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });

                axios.get('/api/participants/getmasterinvitecodeurl?eventId=' + this.eventEdit.id).then(response => {
                    this.masterInviteUrl = response.data;
                    this.loading = false;
                    this.smsUrl = "sms:?&body=" + encodeURI('Hi, please checkout new Colorado Jack Giveback fundraiser, click the link below to setup your online store: ' + '\n' + this.event.title + ' ' + this.masterInviteUrl);
                }).catch((error) => {
                    this.loading = false;
                });

            }).catch((error) => {
                this.loading = false;
            });
            this.profile.email = this.currentUserDetail.email;
            this.profile.firstName = this.currentUserDetail.firstName;
            this.profile.lastName = this.currentUserDetail.lastName;
            this.profile.imageSrc = this.currentUserDetail.imageSrc;
            initFacebookSdk();
            this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            
        },
        methods: {
            calcTotals() {
                this.calculatedTotals.totalSales = this.event.organizerStoreTotal;
                this.calculatedTotals.shippingCosts = parseFloat(this.event.organizerStoreTotal - this.event.organizerStoreSubTotal).toFixed(2);
                this.calculatedTotals.eventSubtotal = parseFloat(this.event.organizerStoreSubTotal - (this.event.organizerStoreTotal * .035)).toFixed(2);
                this.calculatedTotals.adminFee = parseFloat(this.event.organizerStoreTotal * .035).toFixed(2);
                this.calculatedTotals.totalElgibleProfit = parseFloat((this.event.organizerStoreSubTotal - (this.event.organizerStoreTotal * .035)) / 2).toFixed(2);
            },
            async setSelectedFile(file) {
                if (file.raw.type == 'text/csv' || file.raw.type == 'text/plain') {
                    const resp1 = await this.readFile(file);
                    this.csvEmails = resp1.split(",");
                    this.inviteParticipantsCSV(this.csvEmails);
                }
                else {
                    this.$message({
                        message: 'Invalid file type! Please choose a .txt or csv file type.',
                        type: 'warning'
                    });
                }
            },
            readFile(file) {
                return new Promise((resolve, reject) => {
                    let fr = new FileReader();
                    fr.onload = x => resolve(fr.result);
                    fr.onerrror = reject;
                    fr.readAsText(file.raw) // or readAsText(file) to get raw content
                })
            },
            validateForm(form) {
                let isValid = false;
                this.$refs[form].validate((valid) => {
                    isValid = valid;
                });
                return isValid;
            },           
            loadparticipants() {
                axios.get('/api/participants/getparticipants?id=' + this.event.id).then(response => {
                    this.invitedParticipants2 = response.data;
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                });
            },
            copyCode() {
                copyToClipboard("eventCode");
            },
            setSelectedImage(file) {
                this.file = file;
            },
            setCropData(data) {
                this.eventEdit.imageCropData = data;
            },

            addInvitedDonator() {
                let last = this.invitedDonators[this.invitedDonators.length - 1];
                if (last.email.trim().length > 0)
                    this.invitedDonators.push({ counter: this.invitedDonators.length, email: '' });
            },
            removeDonator(p) {
                for (var i = 0; i < this.invitedDonators.length; i++) {
                    if (this.invitedDonators[i] === p) {
                        this.invitedDonators.splice(i, 1);
                        break;
                    }
                }
            },
            inviteDonators() {
                this.loading = true;
                var emails = [];
                this.invitedDonators.forEach(p => {
                    if (p != '')
                        emails.push(p.email);
                });

                axios.post('/api/participants/InviteDonator/', { storeId: this.event.storeId, emails: emails }).then(response => {
                    this.loading = false;
                    this.invDonatorVisible = false;
                    this.$message({
                        message: 'Buyers have been sent an email and invited to view your store!',
                        type: 'success'
                    });

                }).catch((error) => {
                    this.loading = false;
                });
            },
            addInvitedParticipant() {
                //let last = this.invitedParticipants2[this.invitedParticipants2.length - 1];
                //if (last.email.trim().length > 0)
                //    this.invitedParticipants2.push({ counter: this.invitedParticipants2.length, email: '' });
               
                //let last = this.invitedParticipants2[this.invitedParticipants2.length - 1];
                //if (last.email.trim().length > 0)
                //    this.invitedParticipants2.push({ email: '', invitestatus: '', organizername: '', emailcreatedate: '' });
            },
            removeParticipant(p) {
                for (var i = 0; i < this.invitedParticipants2.length; i++) {
                    if (this.invitedParticipants2[i] === p) {
                        this.invitedParticipants2.splice(i, 1);
                        break;
                    }
                }
            },
            inviteParticipantsCSV(emails) {
                this.loading = true;
                axios.post('/api/participants/invite/', { eventId: this.event.id, emails: emails }).then(response => {
                    this.loading = false;
                    this.invPartVisible = false;
                    this.$message({
                        message: 'Participant invited to the event.',
                        type: 'success'
                    });
                    this.invitedParticipant.email = '';
                    //reload particpant view
                    this.loadparticipants();
                }).catch((error) => {
                    this.loading = false;
                });
            },
            inviteParticipants() {
                if (this.validateForm('emailValid') === true) {
                    this.loading = true;
                    var emails = [];
                    if (this.invitedParticipant.email != this.profile.email) {
                        emails.push(this.invitedParticipant.email);
                        //this.invitedParticipants2.forEach(p => {
                        //    if (p != '')
                        //        emails.push(p.email);
                        //});

                        axios.post('/api/participants/invite/', { eventId: this.event.id, emails: emails }).then(response => {
                            this.loading = false;
                            this.invPartVisible = false;
                            this.$message({
                                message: 'Participant invited to the event.',
                                type: 'success'
                            });
                            this.invitedParticipant.email = '';
                            //reload particpant view
                            this.loadparticipants();
                        }).catch((error) => {
                            this.emailErrorMessage = error.response.data;
                            this.loading = false;
                        });
                    }
                    else {
                        this.emailErrorMessage = "Fundraising Organizer Email not allowed to be added to Participant Invite list!";
                        this.loading = false;
                    }
                   
                }                
            },
            inviteParticipantsSMS() {
                if (this.validateForm('phoneValid') === true) {
                    this.loading = true;
                    var phones = [];
                    if (this.invitedParticipant.phone != this.profile.phone) {
                        phones.push(this.invitedParticipant.phone);

                        axios.post('/api/participants/inviteSMS/', { eventId: this.event.id, phones: phones }).then(response => {
                            this.loading = false;
                            this.invPartVisibleSMS = false;
                            this.$message({
                                message: 'Participant invited via sms to the event.',
                                type: 'success'
                            });
                            //reload particpant view
                            this.loadparticipants();
                        }).catch((error) => {
                            this.emailErrorMessage = error.response.data;
                            this.loading = false;
                        });
                    }
                    else {
                        this.emailErrorMessage = "Fundraising Organizer phone not allowed to be added to Participant Invite list!";
                        this.loading = false;
                    }

                }
            },
            resendInvite(inviteRec) {
                this.loading = true;
                var emails = [];
                var phones = [];

                if (inviteRec.phone != null) {
                    phones.push(inviteRec.phone);
                    axios.post('/api/participants/ResendInviteSMS/', { eventId: this.event.id, phones: phones }).then(response => {

                        this.invPartVisibleSMS = false;
                        this.$message({
                            message: 'Participant resent SMS invite to the event.',
                            type: 'success'
                        });

                    }).catch((error) => {
                        this.loading = false;
                    });
                }
                else {
                    emails.push(inviteRec.email);  
                    axios.post('/api/participants/ResendInvite/', { eventId: this.event.id, emails: emails }).then(response => {

                        this.invPartVisible = false;
                        this.$message({
                            message: 'Participant resent email invite to the event.',
                            type: 'success'
                        });

                    }).catch((error) => {
                        this.loading = false;
                    });
                }
                this.loading = false;
            },
            confirmStopEvent() {
                this.$confirm('You will not be able to run the event again. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.stopEvent();
                });
            },
            stopEvent() {
                this.loading = true;
                axios.post('/api/events/stop/' + this.event.id).then(response => {
                    this.loading = false;
                    this.$message({
                        message: 'The event stopped successfully.',
                        type: 'success'
                    });

                    router.push('/event/detail/' + this.event.id);

                }).catch((error) => {
                    this.loading = false;
                });
            },
            saveChanges() {
                this.$refs["eventEditForm"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        var formData = new FormData();
                        if (this.file != null) {
                            formData.append("file", this.file.raw);
                            for (const [key, value] of Object.entries(this.eventEdit.imageCropData)) {
                                formData.append("imageCropData." + key, value);
                            }
                        }

                        for (const [key, value] of Object.entries(this.eventEdit)) {
                            if (key === "imageCropData")
                                continue;
                            formData.append(key, value);
                        }

                        axios.put('/api/events', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                            this.loading = false;
                            this.editMode = false;
                            this.event.imageSrc = response.data;
                            this.event.title = this.eventEdit.title;
                            this.event.description = this.eventEdit.description;
                            this.$message({
                                message: 'Changes saved to the event.',
                                type: 'success'
                            });
                        }).catch((error) => {
                            this.loading = false;
                        });
                    }
                });              
            }
        }
    }
</script>

<style scoped>
    img {
        height: 300px;
        object-fit: cover;
    }
    .participants, .donators {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        min-height: 370px;
    }
    .sellers {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
    }

    .top-label {
        position: relative;
        top: 22px;
        background: #fff;
        left: 15px;
        font-weight: normal;
        padding: 0 5px;
        font-size:large;
    }
    .count {
        top: 25px;
        left: 15px;
    }
    .title {
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 20px;
        /*min-height: 250px;*/
        flex-direction:inherit;
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 370px) {
        h5 {
            font-size: 1.0rem;
        }
        h6 {
            font-size: .80rem;
            font-weight:normal;
        }
        /*1rem = 16px*/
    }

    /* Small devices (landscape phones, 544px and up) */
    @media (min-width: 544px) {        
        h5 {
            font-size: 1.00rem;
        }
        h6 {
            font-size: .90rem;
        }
        /*1rem = 16px*/
    }

    /* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
    @media (min-width: 768px) {        
        h5 {
            font-size: 1.25rem;
        }
        h6 {
            font-size: .95rem;
        }
        /*1rem = 16px*/
    }    

    /* Large devices (desktops, 992px and up) */
    @media (min-width: 992px) {
        h5 {
            font-size: 1.5rem;
        }

        h6 {
            font-size: 1.0rem;
        }
        /*1rem = 16px*/
    }

    /* Extra large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) {
        h5 {
            font-size: 1.75rem;
        }

        h6 {
            font-size: 1.25rem;
        }
        /*1rem = 16px*/
    }
    
</style>