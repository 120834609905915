<template>
    <div class="res-card center">       
        <template v-if="resetPassword">
            <section class="h5 font-weight-bold mb-3">
                <span>RESET PASSWORD</span>
            </section>
            <div class="mb-3">
                <el-alert v-show="errorMessage.length > 0" :title="errorMessage"
                          type="error"
                          effect="dark">
                </el-alert>
                <el-alert v-show="successMessage.length > 0" :title="successMessage"
                          type="success"
                          effect="dark">
                </el-alert>
            </div>
            <el-form :model="passwordData" :rules="passwordRules" ref="changePasswordForm" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>
                
                <el-form-item label="PASSWORD" prop="password">
                    <el-input type="password" v-model="passwordData.password" v-focus v-fancylabel v-on:keyup.enter="submitChanges"></el-input>
                </el-form-item>
                <el-form-item label="CONFIRM PASSWORD" prop="confirmPassword">
                    <el-input type="password" v-model="passwordData.confirmPassword" v-fancylabel v-on:keyup.enter="submitChanges"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitChanges" :loading="loading" class="float-left">SUBMIT</el-button>
                </el-form-item>
            </el-form>
        </template>
        <template v-else>
            <section>You have reset your password click below to login with the new password</section>
            <section class="text-center">
                <el-button type="primary" @click="routeToLogin" >LOGIN</el-button>
            </section>
        </template>
        </div>
</template>

<script>
    import axios from "axios"
    import router from "../router"; // our vue router instance

    export default {
        name: 'PasswordAndSecurity',
        components: {  },
        data() {
            return {
                loading: false,
                resetPassword: true,
                errorMessage: '',
                successMessage:'',
                passwordData: {},
                passwordRules: {
                    password: [
                        { required: true, type: 'string', pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/g, message: 'Password must be 8 to 15 characters with at least one lowercase, one uppercase letter, one numeric digit, and one special character:', trigger: 'blur' }
                    ],
                    confirmPassword: [
                        {
                            required: true,
                            validator: (rule, value, callback) => {
                                return value === this.passwordData.password;
                            }
                            , message: 'Passwords are not matched', trigger: 'blur'
                        }
                    ]
                }
            }
        },
        methods: {
            submitChanges() {
                this.$refs["changePasswordForm"].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        this.passwordData.token = this.$route.query.token;
                        this.passwordData.email = this.$route.query.email;
                        axios.post("/api/users/resetpassword", this.passwordData).then(response => {
                            this.loading = false;
                            if (response.data == "1") {
                                this.resetPassword = false;
                                this.errorMessage = "";
                                this.successMessage = "You have reset your password we are redirecting you to the login page";
                                router.push("/login");
                            }
                            else {
                                this.successMessage = "";
                                this.errorMessage = "Please try again later.";
                            }
                        }).catch((error) => {
                            this.loading = false;
                            this.successMessage = "";
                            this.errorMessage = "Please try again later.";
                        });
                    }
                });
            },
            routeToLogin() {
                router.push("/login");
            }
        },
        computed: {
           
        },
        mounted() {
          
        },
        created() {
          
        }
    }
</script>


<style>
  
</style>
