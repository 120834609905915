<template>
    <section class="h5 font-weight-medium mt-5">
        <span>PROFILE</span>
        <span class="float-right"><el-button icon="el-icon-edit" circle @click="editMode=true"></el-button></span>
    </section>
    <hr />  
    <template v-if="editMode">
        <div class="row mt-5">           
            <div class="col-12 col-sm-6" style="height: 300px">
                <section>
                    <div class="font-weight-medium">
                        EMAIL
                    </div>
                    <div class="mt-2" style="color: #983222">
                        {{profile.email}}
                    </div>
                </section>
                <section>
                    <div class="font-weight-medium">
                        MOBILE PHONE
                    </div>
                    <div class="mt-2" style="color: #983222">
                        {{profile.phone}}
                    </div>
                </section>
                <el-form :model="profile" :rules="profileRules" ref="profileForm" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>
                    <section>
                        <el-form-item label="FIRST NAME" prop="firstName" style="color: #983222">
                            <el-input type="text" v-model="profile.firstName" autocomplete="off" v-focus v-fancylabel v-on:keyup.enter="submitChanges"></el-input>
                        </el-form-item>
                    </section>
                    <section>
                        <el-form-item label="LAST NAME" prop="lastName" style="color: #983222">
                            <el-input type="text" v-model="profile.lastName" autocomplete="off" v-fancylabel v-on:keyup.enter="submitChanges"></el-input>
                        </el-form-item>
                    </section>
                </el-form>
            </div>
            <div class="col-12 col-sm-6 justify-content-end d-flex" style="height: 200px" >
                <div style="width:250px;height:250px">
                    <ImageUploader @crop="setCropData" @image-selected="setSelectedImage" :image="profile.imageSrc"></ImageUploader>
                </div>
            </div>
        </div>
        <div class="row mt-5" style="padding-top:30px; justify-content:center">
            <el-form-item>
                <el-button type="primary" plain @click="editMode=false" class="center">CANCEL</el-button>
                <el-button type="primary" @click="submitChanges" :loading="loading" class="center">SUBMIT CHANGES</el-button>
            </el-form-item>
        </div>
    </template>
    <template v-else>
        <div class="row">           
            <div class="col-12 col-sm-6">
                <section>
                    <div class="font-weight-medium">
                        EMAIL
                    </div>
                    <div class="mt-2 font-weight-light">
                        {{profile.email}}
                    </div>
                </section>
                <section>
                    <div class="font-weight-medium">
                        MOBILE PHONE
                    </div>
                    <div class="mt-2 font-weight-light">
                        {{profile.phone}}
                    </div>
                </section>
                <section>
                    <div class="font-weight-medium">
                        FIRST NAME
                    </div>
                    <div class="mt-2 font-weight-light">
                        {{profile.firstName}}
                    </div>
                </section>
                <section>
                    <div class="font-weight-medium">
                        LAST NAME
                    </div>
                    <div class="mt-2 font-weight-light">
                        {{profile.lastName}}
                    </div>
                </section>
            </div>
            <div class="col-12 col-sm-6 justify-content-end d-flex">
                <div style="width:300px;height:300px" class="profile-image">
                    <el-image :src="profile.imageSrc">
                        <template #error>
                            <div class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image>
                </div>
            </div>
        </div>
    </template>

</template>

<script>
    import axios from "axios"
    import store from "@/store"
    import { mapGetters } from "vuex"
    import ImageUploader from "./ImageUploader"

    export default {
        name: 'Profile',
        components: { ImageUploader },
        data() {
            return {
                editMode: false,
                loading: false,
                file: null,
                profile: {
                    email: '',
                    phone: '',
                    firstName: '',
                    lastName: '',
                    imageSrc: '',
                    imageCropData: {}
                },
                profileRules: {
                    firstName: [
                        { required: true, message: 'First Name is required', trigger: 'blur' }
                    ],
                    lastName: [
                        { required: true, message: 'Last Name is required', trigger: 'blur' }
                    ]
                }
            }
        },
        methods: {
            setSelectedImage(file) {
                this.file = file;
            },
            setCropData(data) {
                this.profile.imageCropData = data;
            },
            submitChanges() {
                this.$refs["profileForm"].validate((valid) => {
                    if (valid) {
                        var formData = new FormData();
                        if (this.file != null) {
                            formData.append("file", this.file.raw);
                            for (const [key, value] of Object.entries(this.profile.imageCropData)) {
                                formData.append("imageCropData." + key, value);
                            }
                        }

                        for (const [key, value] of Object.entries(this.profile)) {
                            if (key === "imageCropData")
                                continue;
                            formData.append(key, value);
                        }
                        this.loading = true;
                        axios.put("/api/users", formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        }).then(response => {
                            this.currentUserDetail.firstName = this.profile.firstName;
                            this.currentUserDetail.lastName = this.profile.lastName;
                            this.currentUserDetail.imageSrc = response.data;
                            this.profile.imageSrc = response.data;
                            store.commit("setLoggedInUser", this.currentUserDetail);
                            this.$message({
                                message: 'Changes saved to the event.',
                                type: 'success'
                            });
                            this.editMode = false;
                            this.loading = false;
                        }).catch((error) => {
                            this.loading = false;
                        });   
                    }
                });                
            }
        },
        computed:{
            ...mapGetters([
                'currentUserDetail'
            ])
        },
        mounted() {
           
        },
        created() {
            this.profile.email = this.currentUserDetail.email;
            this.profile.phone = this.currentUserDetail.phone;
            this.profile.firstName = this.currentUserDetail.firstName;
            this.profile.lastName = this.currentUserDetail.lastName;
            this.profile.imageSrc = this.currentUserDetail.imageSrc;
        }
    }
</script>


<style>
   .profile-image img{
       border-radius:100% !important;
   }
</style>
