<template>
    <el-dialog fullscreen="true"
               v-model="visible" :before-close="open=false">
        <div class="container content">
            <template v-if="product.bagsList.length==1">
                <div class="row">
                    <div class="col-12 col-sm-8" style="text-align:center">
                        <div class="col-12 font-weight-bold title">
                            <div class="h5 text-center font-weight-bold" style="word-break: keep-all; text-align: center;">{{product.name}}</div>
                            <div class="h5 mt-2" style="word-break: keep-all; text-align: center;">{{product.description}}</div>
                            <!--<div class="h6 mt-2">Product Ingredients: {{product.bagsList[0].ingredients}}</div>-->
                        <div class="h6 mt-2">Product Weight: {{product.bagsList[0].weight}}</div>
                        </div>
                        <div class="col-12 text-center">
                            <el-image :src="product.bagsList[0].imageSrc" style="max-width:170px">
                                <template #error>
                                    <div class="image-slot">
                                        <i class="el-icon-picture-outline"></i>
                                    </div>
                                </template>
                            </el-image>
                        </div>
                    </div>
                    <div class="col-12 col-sm-4 text-center">
                        <!--<div class="free-shipping h3 font-weight-bold text-center bg-danger text-white p-2" v-bind:class="{hidden:!product.freeShipping}">FREE SHIPPING</div>-->
                        <div class="free-shipping h3 font-weight-bold text-center bg-danger text-white p-2" v-if="product.freeShipping">FREE SHIPPING</div>
                        <div class="h3 mt-3">SUBTOTAL</div>
                        <div class="total h2 mt-3 font-weight-bold">${{totalPrice.toFixed(2)}}</div>
                        <!--<div class="mt-5">To add more than one of these Quantity configurations, add to the overall quanity field below.</div>-->
                        <div class="h4 mt-3">Overall Quantity</div>
                        <div class="counter mt-2 row align-items-center no-gutters" style="margin:0 auto">
                            <div class="col p-0">
                                <el-button icon="el-icon-minus" circle @click="setCount(-1)"></el-button>
                            </div>
                            <div class="count-box-mul col p-0">
                                <el-input v-model="product.count"></el-input>
                            </div>
                            <div class="col p-0">
                                <el-button icon="el-icon-plus" circle @click="setCount(1)"></el-button>
                            </div>
                        </div>

                        <div class="mt-3 row addcart">
                            <el-button type="danger" style="background-color: #983222; font-size:inherit" class="addCartBtn" :disabled="!enableAddToCart" @click="addToCart()">ADD TO CART ${{totalPrice.toFixed(2)}}</el-button>
                            <el-button class="addCartBtn" @click="visible=false">CANCEL</el-button>

                        </div>
                        <div class="col-12">
                            <payment-form-button v-on:payment-success="paymentSuccess" :product="this.product" :total="totalPrice.toFixed(2)" :filledBags="this.filledBags" ref="paymentFormButton" :key="foraceUpdate"></payment-form-button>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else >
                <div class="row">
                    <div class="col-12">
                        <div class="font-weight-bold title">
                            <template v-if="screenWidth<992">
                                <div class="h6 text-center font-weight-bold" style="word-break: normal">{{product.name}}</div>
                                <div class="mt-1 h5 font-weight-bold text-center color" style="color: #983222">CHOOSE YOUR FLAVORS</div>
                            </template>
                            <template v-else>
                                <div class="h4 text-center font-weight-bold" style="word-break: normal">{{product.name}}</div>
                                <div class="mt-3 h3 font-weight-bold text-center" style="color: #983222">CHOOSE YOUR FLAVORS</div>
                            </template>                            
                        </div>
                        <div class="bags-slots mt-2" style="overflow:auto">
                            <template v-if="screenWidth<992">
                                <ul style="width:780px;margin:0 auto;">
                                    <li class="mr-1 ml-1 text-center bag-slot" :class="{'empty':bag.id==null,'filled':bag.id!==null}" style="position:relative" v-for="(bag,index) in selectedBags" :key="bag.id">
                                        <div :class="{hidden:bag.id==null}" class="h6 font-weight-bold">{{bag.count}}</div>
                                        <img :class="{'empty-slot':bag.id==null,'filled-slot':bag.id!==null}" @click="addFlavour(bag)" :src="bag.imageSrc" />
                                        <div :class="{hidden:bag.id!==null}" class="h6 font-weight-bold" style="margin-top:-40px">{{index + 1}}</div>
                                        <a :class="{hidden:bag.id==null}" class="d-inline-block mt-2 text-danger font-weight-bold caps c-pointer" @click="removeFlavour(bag)" style="text-decoration:none">REMOVE</a>
                                    </li>
                                </ul>
                            </template>
                            <template v-else>
                                <ul style="width:780px;margin:0 auto;text-align:center">
                                    <li class="mr-1 ml-1 text-center bag-slot" :class="{'empty':bag.id==null,'filled':bag.id!==null}" style="position:relative" v-for="(bag,index) in selectedBags" :key="bag.id">
                                        <div :class="{hidden:bag.id==null}" class="h2 font-weight-bold">{{bag.count}}</div>
                                        <el-button @click="addFlavour(bag)" class="add-flavour-action absolute-center" v-if="selectedBagsCount<product.quantity">ADD FLAVOR</el-button>
                                        <img :class="{'empty-slot':bag.id==null,'filled-slot':bag.id!==null}" :src="bag.imageSrc" />
                                        <div :class="{hidden:bag.id!==null}" class="h2 font-weight-bold" style="margin-top:-40px">{{index + 1}}</div>
                                        <a :class="{hidden:bag.id==null}" class="d-inline-block mt-2 text-danger font-weight-bold caps c-pointer" @click="removeFlavour(bag)" style="text-decoration:none">REMOVE</a>
                                    </li>
                                </ul>
                            </template>
                        </div>

                        <el-carousel arrow="always" height="575px" interval="5000" indicator-position="none">
                            <el-carousel-item class="bag-slot"  v-for="bag in product.bagsList" :key="bag.id" width="300">
                                <div class="product-image" style="max-width:220px; margin:20px auto">
                                    <template v-if="screenWidth<992">
                                        <el-button @click="addFlavour(bag)" :loading="loading" class="add-flavour-action absolute-center" v-if="selectedBagsCount<product.quantity">ADD FLAVOR</el-button>
                                        <el-image :src="bag.imageSrc">
                                            <template #error>
                                                <div class="image-slot">
                                                    <i class="el-icon-picture-outline"></i>
                                                </div>
                                            </template>
                                        </el-image>
                                    </template>
                                    <template v-else text-align:center>
                                        <el-button @click="addFlavour(bag)" :loading="loading" class="add-flavour-action absolute-center" v-if="selectedBagsCount<product.quantity">ADD FLAVOR</el-button>
                                        <el-image :src="bag.imageSrc">
                                            <template #error>
                                                <div class="image-slot">
                                                    <i class="el-icon-picture-outline"></i>
                                                </div>
                                            </template>
                                        </el-image>
                                    </template>
                                    <div class="h5 caps" style="height:auto; max-width: 300px; margin: 0 auto; word-break: normal; text-align: center">
                                        {{bag.name}}
                                    </div>
                                    <div class="h6 caps" style="height: 25px; max-width: 300px; margin: 0 auto; word-break: normal; text-align: center">
                                        {{bag.weight}}
                                    </div>
                                    <div class="h6 mt-2" style="margin:0 auto; word-break:normal; text-align:center">
                                        {{bag.description}}
                                    </div>
                                    <div class="h4 font-weight-bold mt-2" style="color: #983222; text-align: center">
                                        ${{bag.price.toFixed(2)}}
                                    </div>
                                    <div class="h-75 mt-1" style="margin:0 auto; word-break:normal; text-align:center" v-if="bag.ingredients!=null">
                                        Ingredients: {{bag.ingredients}}
                                    </div>
                                    <!--<div class="col-12 row counter mt-3 align-items-center" style="max-width: 380px;margin: 0 auto">
    </div>-->
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
                <el-dialog :show-close="false" :close-on-click-modal ="false" v-model="enableAddToCart" :width="dialogWidth" :top="dialogTop"  >
                    <div class="text-center">
                        <div class="free-shipping h3 font-weight-bold text-center bg-danger text-white p-2" v-bind:class="{hidden:!product.freeShipping}">FREE SHIPPING</div>
                        <div class="h3 mt-5">SUBTOTAL</div>
                        <div class="total h2 mt-3 font-weight-bold">${{totalPrice.toFixed(2)}}</div>
                        <div class="mt-3 text-center">
                            <!--<div class="mt-5">Quantity configuration, you can add multiple product configurations below</div>-->
                            <div class="mt-2" style="margin:0 auto">
                                <div class="h4">Overall Product Quantity</div>
                                <div class="counter mt-2 row align-items-center no-gutters" style="margin:0 auto">
                                    <div class="col p-0">
                                        <el-button icon="el-icon-minus" circle @click="setCount(-1)"></el-button>
                                    </div>
                                    <div class="count-box-mul col p-0">
                                        <el-input v-model="product.count"></el-input>
                                    </div>
                                    <div class="col p-0">
                                        <el-button icon="el-icon-plus" circle @click="setCount(1)"></el-button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mt-5 addcart text-center">
                            <el-button type="danger" style="background-color: #983222" class="addCartBtn" :disabled="!enableAddToCart" @click="addToCart()">ADD TO CART ${{totalPrice.toFixed(2)}}</el-button>
                            <el-button class="addCartBtn" @click="showProduct(product.id)">CANCEL</el-button>
                            <payment-form-button v-on:payment-success="paymentSuccess" :product="this.product" :total="totalPrice.toFixed(2)" :filledBags="this.filledBags" ref="paymentFormButton" :key="foraceUpdate"></payment-form-button>
                        </div>
                        <div class="h5 mt-5">
                            <div class="text-danger" v-if="selectedBagsCount>product.quantity">
                                You have selected {{selectedBagsCount}} bag<span v-if="selectedBagsCount>1">s</span>. Please remove {{selectedBagsCount-product.quantity}} bag<span v-if="selectedBagsCount-product.quantity>1">s</span> to enable the Add To Cart button...
                            </div>
                            <div class="text-info" v-if="selectedBagsCount > 0 && selectedBagsCount<product.quantity">
                                You have selected {{selectedBagsCount}} bag<span v-if="selectedBagsCount>1">s</span>. Please add {{product.quantity-selectedBagsCount}} bag<span v-if="product.quantity-selectedBagsCount>1">s</span> to enable the Add To Cart button...
                            </div>
                        </div>
                    </div>
                </el-dialog>
            </template>    
        </div>
       
    </el-dialog>
</template>

<script>

    import axios from "axios"
    import store from "@/store"
    import PaymentFormButton from "./PaymentFormButton"
    import OrderReview from "./OrderReview"
    import router from "../router";

    export default {
        name: "ProductDetail",
        components: { PaymentFormButton },
        data() {
            return {
                product: {},
                visible: false,
                selectedBags: [],
                loading: false,
                foraceUpdate: 0,                
               // productCountDialogVisible: false;
            }
        },
        computed: {
            filledBags() {
                return this.selectedBags.filter(b=>b.id!=null);
            },
            totalPrice() {
                if (this.product.bagsList.length == 1)
                    return this.product.count * this.product.price;
                else {
                    let total = 0;
                    this.filledBags.forEach((item) => {
                        total += (item.price * item.count);
                    });
                    return total * this.product.count;
                }
            },
            enableAddToCart() {
                if (this.product.bagsList.length == 1)
                    return true;
                else {
                    let count = 0;
                    this.filledBags.forEach(item => {
                        count += item.count;
                    });

                    return count == this.product.quantity;
                }
            },
            selectedBagsCount() {
                let count = 0;
                this.filledBags.forEach(item => {
                    count += item.count;
                });
                return count;
            },
            dialogWidth() {
                if (screen.width <= 576)
                    return "100%";
                else return "500px";
            },
            dialogTop() {
                if (screen.width <= 576)
                    return "0";
                else return "15vh";
            },
            screenWidth() {
                return screen.width;
            }
        },
        methods: {
           
            showProduct(id) {
                //get store detail
                this.product.count = 1;
                axios.get("/api/products/" + id).then(response => {
                    this.product = response.data;
                    this.product.count = 1;
                    this.selectedBags=[];
                    this.product.bagsList.forEach((item, index) => {
                        if (index < this.product.quantity) {
                            item.count = 0;
                            item.selected = false;
                            this.selectedBags.push({ id: null, count: 0, imageSrc: 'https://coloradojackgivebacksa.blob.core.windows.net/bags/empty-slot.png' })
                        }
                    });

                    this.visible = true;
                    this.foraceUpdate++;
                }).catch((error) => {
                });
            },
            addFlavour(bag) {
                this.loading = true;
                let sBag = this.selectedBags.filter(item => item.id == bag.id);
                if (sBag.length > 0)
                    sBag[0].count++;
                else {
                    let emptyItems = this.selectedBags.filter(item => item.id == null);
                    if (emptyItems.length > 0) {
                        bag.selected = true;
                        emptyItems[0].id = bag.id;
                        emptyItems[0].name = bag.name;
                        emptyItems[0].price = bag.price;
                        emptyItems[0].count = 1;                        
                        emptyItems[0].imageSrc = bag.imageSrc;
                    }
                }
                this.loading = false;
            },
            removeFlavour(bag) {
                bag.count--;
                if (bag.count == 0) {                    
                    bag.id = null;
                    bag.imageSrc = 'https://coloradojackgivebacksa.blob.core.windows.net/bags/empty-slot.png';
                }
            },
            setCount(v) {
                if (this.product.count + v > 0)
                    this.product.count += v;
            },
            //setBagCount(bagId,v) {
            //    //get bag
            //    const bag = this.product.bagsList.filter(b => b.id === bagId)[0];
            //    if (bag.count + v >= 0) 
            //        bag.count += v;                   
            //},
            addToCart() {
                //define product to add to cart
                let product = {
                    id: this.product.id, name: this.product.name, freeShipping: this.product.freeShipping, ShippingMethodID: this.product.shippingMethodID, shippingCost: this.product.shippingCost, total: this.totalPrice, quantity: this.product.quantity, count: this.product.count, price: this.product.price, imageSrc: this.product.imageSrc, bags: []
                };

                if (this.product.bagsList.length == 1) {
                    let item = this.product.bagsList[0];
                    product.bags.push({ id: item.id, name: item.name, imageSrc: item.imageSrc, price: product.price, total: product.price, count: product.quantity });
                   
                    product.total = product.price * product.count;
                }
                else if (this.product.bagsList.length > 1) {
                    this.product.price = 0;
                    product.total = 0;
                    this.filledBags.forEach((item) => {
                        let bag = { id: item.id, name: item.name, imageSrc: item.imageSrc, price: item.price, total: (item.count * item.price), count: item.count };
                        product.bags.push(bag);
                        product.price += item.price * item.count;
                    });
                    product.total = product.price * product.count;                
                }
               
                //send to cart
                store.dispatch("addToCart", product);
                this.visible = false;
            },
            paymentFail(paymentMethod, orderStatus) {
                this.paymentError = orderStatus.message;
                // alert(failError);
            },
            //will be called from payment form when user payes with Google/Apple pay
            paymentSuccess(paymentMethod, orderData) {
                    let order = {};
                    order.number = orderData.orderNum;
                    order.total = parseFloat(orderData.total);
                    order.deliveryMethod = orderData.deliveryMethod;
                    order.email = orderData.email;
                    order.shippingAddressString = orderData.shippingAddressString;
                    order.billingAddressString = orderData.billingAddressString;
                    store.dispatch('setCompletedOrder', order);
            }
        }
    }
</script>

<style>
    .counter .el-button {
        width: 50px;
        height: 50px;
    }
    .el-button.is-circle {
        border-radius: 50%;
        padding: 8px !important;
    }
    .counter .count {
        padding: 0px 50px;
        font-size: 56px;
        font-weight: bold;
    }

    .counter .el-button i {
        font-size: 32px;
    }

    .addcart .el-button {
        font-size: 20px;
    }

    .count-box input {
        width: 100px;
        font-size: 24px;
        text-align: center;
        font-weight: bold;
    }
    .count-box-mul input {
        font-size: 24px;
        text-align: center;
        font-weight: bold;
    }
    img.empty-slot {
        /*max-width:120px;*/
        max-width: 70px;
    }
    img.filled-slot {
        height: 80px;
    }

    .product-image {
        max-width: 170px;
        margin: 0 auto;
        position: relative;       
    }
        .product-image img {
            transition: all .5s ease-out;
        }
        .product-image:hover img, .bag-slot.filled:hover img {
            opacity: .3;
        }
        .product-image:hover .add-flavour-action, .bag-slot.filled:hover .add-flavour-action {
            opacity: 1;
        }

    .add-flavour-action {
        z-index: 1;
        opacity: 0;
        box-shadow: 0 5px 20px 0 rgb(0 0 0 / 50%);
        transition: all .5s ease-out !important;
        background-color: #983222 !important;
        color: #fff !important;
        border: none !important;
    }

    .bag-slot .add-flavour-action {
        padding: 10px !important;
        font-size: 12px !important;
    }

    .addcart button {
        width: 48%;
        border-radius: 1px;
    }
    .addCartBtn {
        font-size: 12px !important;
        /*padding-right: 60px !important;*/
        text-align:center;
    }
    .el-carousel__item h3 {
        /*color: #475669;*/
        font-size: 14px;
        /*opacity: 0.75;*/
        /*line-height: 200px;*/
        margin: 0;
        text-align: center;
    }

    .el-carousel__item:nth-child(2n) {
        /*background-color: azure #99a9bf;*/
        background-color: white;
    }

    .el-carousel__item:nth-child(2n + 1) {
        /*background-color: #d3dce6;*/
        background-color: white;
    }

    .el-carousel__arrow {
        background-color: #262626;
    }

</style>