<template>
    <div v-if="!proceed" class="global-message">
        <el-alert v-show="proceedErrorMessage.length > 0" :title="proceedErrorMessage" :closable="false"
                  type="error"
                  effect="dark">
        </el-alert>
    </div>
    <div id="" class="container form-input form-center" v-if="proceed">
        <div class="row mt-5">
            <div class="col-12 order-last order-sm-first col-sm-6 col-md-4 mt-sm-0 mt-3" style="max-height:200px">
                <div class="h5 font-weight-bold">The Fundraising coordinator:</div>
                <div class="h6 font-weight-bold">{{store.organizerName}} has invited you to participate in the Fundraiser:</div>
                <div class="h5" style="color: #983222">{{event.title}}</div>
                <div class="mt-2" style="color: #983222">Fundraiser Description:</div>
                <div class="" style="color: #983222">{{event.description}}</div>
            </div>
            <div class="col-12 order-first order-sm-last col-sm-6 col-md-8">
                <div class="image-cover" style="height:300px">
                    <el-image :src="event.imageSrc" style="border-radius:30px">
                        <template #error>
                            <div class="image-slot">
                                <i class="el-icon-picture-outline"></i>
                            </div>
                        </template>
                    </el-image>
                </div>
            </div>
        </div>

        <div class="row" style="justify-content:center">
            <div class="col-12">
                <transition name="el-fade-in-linear" v-if="currentStep==1">
                    <div class="widget step">
                        <hr />
                        <el-form :model="store" :rules="step1Rules" ref="step1Form" :label-position="top" hide-required-asterisk="true" show-message="false">
                            <section class="">
                                <p class="">Please personalize your store title for the event fundraiser, by entering your information into the fields below and click Next to complete the sign up process! This is your own personized eCommerce Store that you will be sending out to buyers, please add an appropriate title, description and picture that they will be able to identify you.</p>
                                <p class="">Remember you will earn 50% of your sales, minus shipping costs. So if you sell $1000 of Colorado Jack Popcorn your fundraiser will recieve $500 back. You will be able to track your totals in your own personal dashboard once you have signed up.</p>
                            </section>
                            <el-form-item label="Store Title" prop="title">
                                <el-input v-model="store.title" v-fancylabel></el-input>
                            </el-form-item>
                            <el-form-item label="What is your sales goal for your store?" prop="targetAmount">
                                <el-input type="tel" v-model="store.targetAmount" v-fancylabel></el-input>
                            </el-form-item>
                            <el-form-item prop="description" label="Please add a description">
                                <el-input type="textarea" :autosize="{ minRows: 5, maxRows: 10}" v-model="store.description" v-fancylabel></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button style="background-color: #983222" type="primary" @click="next('step1Form')" :disabled="!form1Valid" v-if="currentStep==1" class="float-left">NEXT</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </transition>
                <transition name="el-fade-in-linear" v-if="currentStep==2">
                    <div class="widget step">
                        <hr />
                        <el-form :model="store" :rules="step2Rules" ref="step2Form" :label-position="top" hide-required-asterisk="true" show-message="false">
                            <section class="form-title">
                                <p class="mt-5 center">Upload media for your store, this image will be overlayed on top of the Fundraiser image.  Typically a picture of yourself would be preferred. This image can be changed once your store has been created.</p>
                            </section>
                            <el-form-element>
                                <div style="height:200px;margin: 0 auto; margin-bottom:20px">
                                    <ImageUploader @crop="setCropData" @image-selected="setSelectedImage" :showUploadButton="false"></ImageUploader>
                                </div>
                            </el-form-element>
                            <div class="mt-3">
                                <el-form-item>
                                    <el-button style="background-color: #983222" type="primary" @click="currentStep--" class="float-left">BACK</el-button>
                                    <el-button style="background-color: #983222" type="primary" @click="currentStep++" v-if="currentStep==2" class="float-left">NEXT</el-button>
                                </el-form-item>
                            </div>
                        </el-form>
                    </div>
                </transition>
                <transition name="el-fade-in-linear" v-if="currentStep==3&&store.formattdPhone!=''">
                    <div class="widget step">
                        <hr />
                        <el-form :model="store" :rules="step3Rules" ref="step3Form" :label-position="top" hide-required-asterisk="true" show-message="false">
                            <el-alert class="mt-4" v-show="errorMessage1" :title="errorMessage1"
                                      type="error"
                                      effect="dark">
                            </el-alert>
                            <section class="form-title">
                                <h4>Complete your profile</h4>
                            </section>
                            <el-form-item label="User phone - 555-555-5555" prop="phone">
                                <el-input type="text" v-model="store.formattedPhone" v-on:keyup.enter="focusOut" @blur="focusOut" v-fancylabel>{{store.formattedPhone}}</el-input>
                            </el-form-item>
                            <el-form-item label="Email Address" prop="email">
                                <el-input type="text" v-model="store.email" v-fancylabel @change="confirmEmail" v-on:keyup.enter="confirmEmail" v-on:keydown.tab="confirmEmail">{{store.email}}</el-input>
                            </el-form-item>
                            <el-form-item label="First Name" prop="firstName">
                                <el-input type="text" v-model="store.firstName" v-fancylabel></el-input>
                            </el-form-item>
                            <el-form-item label="Last Name" prop="lastName">
                                <el-input type="text" v-model="store.lastName" v-fancylabel></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button style="background-color: #983222" type="primary" @click="currentStep--" class="float-left">BACK</el-button>
                                <el-button style="background-color: #983222" type="primary" @click="sendConfirmPhoneCode" :loading="loading" class="float-left">VERIFY PHONE</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </transition>
                <transition name="el-fade-in-linear" v-if="currentStep==4&&store.formattdPhone!=''">
                    <div class="widget step">
                        <el-alert class="mt-4" v-show="errorMessage1" :title="errorMessage1"
                                  type="error"
                                  effect="dark">
                        </el-alert>
                        <hr />
                        <el-form :model="store" :rules="step4Rules" ref="step4Form" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>
                            <section class="form-title">
                                <h5 style="font-weight:500">We have sent you a verification code to your phone {{store.formattedPhone}}, once you recieve it please enter it here:</h5>
                            </section>
                            <el-form-item>
                                <el-input type="text" style="width: 100%;" v-model="store.phoneCode" maxlength="6" show-word-limit class="email-code" autocomplete="off" v-focus v-fancylabel v-on:keyup.enter="confirmPhoneCode"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="danger" style="background-color: #983222" @click="confirmPhoneCode" :loading="loading" class="float-left">CONFIRM</el-button>
                                <el-button type="primary" plain @click="resendConfirmPhoneCode" :loading="loading" class="float-left">RESEND</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </transition>
                <transition name="el-fade-in-linear" v-if="currentStep==5">
                    <div class="widget step">
                        <hr />
                        <el-alert class="mt-4" v-show="errorMessage1" :title="errorMessage1"
                                  type="error"
                                  effect="dark">
                        </el-alert>
                        <el-form :model="store" :rules="step5Rules" ref="step5Form" :label-position="top" hide-required-asterisk="true" show-message="false" @submit.prevent>
                            <div class="row col-12" style="text-align: left">
                                <div class="col-md-4">Phone Verfication Status:</div>
                                <div class="row col-8 center font-weight-bold" style="justify-content: left; color: #983222">Success</div>
                            </div>
                            <div class="row col-12" style="text-align: left">
                                <div class="col-md-4">STORE TITLE:</div>
                                <div class="row col-8 center font-weight-bold" style="justify-content: left; color: #983222">{{store.title}}</div>
                            </div>
                            <div class="row col-12" style="text-align: left">
                                <div class="col-md-4">STORE DESCRIPTION:</div>
                                <div class="row col-8 center font-weight-bold" style="justify-content: left; color: #983222">{{store.description}}</div>
                            </div>
                            <div class="row col-12" style="text-align: left">
                                <div class="col-md-4">SALES GOAL:</div>
                                <div class="row col-8 center font-weight-bold" style="justify-content: left; color: #983222">{{store.targetAmount}}</div>
                            </div>
                            <div class="row col-12" style="text-align: left">
                                <div class="col-md-4">NAME:</div>
                                <div class="row col-8 center" style="justify-content: left; color: #983222"><span class="font-weight-bold">{{store.firstName +' ' + store.lastName}}</span></div>
                            </div>
                            <div class="row col-12" style="text-align: left">
                                <div class="col-md-4">EMAIL:</div>
                                <div class="row col-8 center font-weight-bold" style="justify-content: left; color: #983222">{{store.email}}</div>
                            </div>
                            <div class="row col-12" style="text-align: left">
                                <div class="col-md-4">PHONE:</div>
                                <div class="row col-8 center font-weight-bold" style="justify-content: left; color: #983222">{{store.formattedPhone}}</div>
                            </div>
                            <div class="row col-12" style="text-align: center; justify-content:center">
                                <div class="col-md-4"></div>
                                <div class="row col-8 center font-weight-bold" style="justify-content:center">
                                    <el-button type="danger" style="background-color: #983222; width: 100%;" @click="submitStore" :loading="loading" class="center">Create Store</el-button>
                                </div>
                            </div>
                        </el-form>
                    </div>
                </transition>
                <transition name="el-fade-in-linear" v-if="currentStep==6">
                    <div class="widget step">
                        <section class="row col-12">
                            <div class="col-md-4"></div>
                            <div class="col-md-8 h2 font-weight-bold center" style="justify-content: center; text-align: center">
                                <i class="el-icon-circle-check" style="color:#67C23A;"></i>CONGRATULATIONS
                            </div>
                            <div class="row col-12 h5 mt-4" style="text-align: center">
                                <div class="col-md-4"></div>
                                <div class="row col-8 center" style="justify-content: center"><span class="font-weight-bold">{{store.firstName +' ' + store.lastName}}</span></div>
                            </div>
                            <div class="row col-12" style="text-align: center">
                                <div class="col-md-4"></div>
                                <div class="row col-8 center h5" style="justify-content:center">Thanks for creating your Online Store:</div>
                            </div>
                            <div class="row col-12" style="text-align: center">
                                <div class="col-md-4"></div>
                                <div class="row col-8 center h5 font-weight-bold" style="justify-content:center">{{store.title}}</div>
                            </div>
                            <div class="row col-12">
                                <div class="col-md-4"></div>
                                <div class="col-8 mt-4 center" style="justify-content:center; text-align:center">
                                    <el-button style="background-color: #983222" type="primary" @click="authenticateLogin" :loading="loading">Go to your Dashboard</el-button>
                                </div>
                            </div>
                        </section>
                    </div>
                </transition>
            </div>            
        </div>        
    </div>
</template>

<script>
    import axios from "axios"
    import store from "@/store"
    import ImageUploader from "./ImageUploader"
    import router from "../router";
    import { mapGetters } from "vuex"

    export default {
        name: 'SignupParticipant',
        components: { ImageUploader },
        data() {
            return {
                emailExists: false,
                isMounted: false,
                currentStep: 1,  
                errorMessage: '',
                errorMessage1: '',
                step3FormValid:false,
                loading: false,
                file: null,
                proceed: false,
                proceedErrorMessage: '',
                statusMessage: '',
                userExists: false,
                preventNextIteration: false,
                event: {},
                store: {                    
                    imageCropData: {},
                    title: '',
                    targetAmount:'',
                    description: '',
                    firstName: '',
                    lastName: '',
                    code: '',
                    email: '',
                    organizerName: '',
                    phone: "0",
                    formattedPhone: '',
                    emailCode: '',
                    phoneCode: '',
                    encryptedEmailcode: '',
                    eventId: '',
                },
                login: {
                    mobile: '',
                    email: '',
                    password: ''
                },
                step1Rules: {
                    title: [
                        { required: true, message: 'store title is required', trigger: 'blur' }
                    ],
                    description: [
                        { required: true, message: 'store description is required', trigger: 'blur' }
                    ],
                    targetAmount: [/* eslint-disable-next-line no-useless-escape*/
                        { required: true, type:'string',pattern: /^\d+(\.\d{1,2})?$/g, message: 'Enter the target amount', trigger: 'blur' }
                    ],
                },
                step2Rules: {
                  
                },
                step3Rules: {
                    firstName: [
                        { required: true, message: 'First Name is required', trigger: 'blur' }
                    ],
                    lastName: [
                        { required: true, message: 'Last Name is required', trigger: 'blur' }
                    ],                   
                    email: [
                        { required: true, type: 'email', message: 'Invalid email address', trigger: 'blur' }
                    ],
                    phone: [
                        { required: true, type: 'string', pattern: /^\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/g, message: 'A valid Phone Number is required', trigger: 'blur' }
                    ]     
                },
                step4Rules: {
                    emailCode: [
                        { required: true, message: 'Invalid verification code', trigger: 'blur' }
                    ]
                },
                step5Rules: {
                    password: [
                        { required: true, type: 'string', pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/g, message: 'Password must be 8 to 15 characters with at least one lowercase, one uppercase letter, one numeric digit, and one special character:', trigger: 'blur' }
                    ],
                    confirmPassword: [
                        {
                            required: true,
                            validator: (rule, value, callback) => {
                                return value === this.store.password;
                            }
                            , message: 'Passwords are not matched', trigger: 'blur'
                        }
                    ],
                }
            }
        },
        methods: {
            focusOut: function (event) {
                if (['Arrow', 'Backspace', 'Shift'].includes(event.key)) {
                    this.preventNextIteration = true;
                    return;
                }
                if (this.preventNextIteration) {
                    this.preventNextIteration = false;
                    return;
                }
                this.store.phone = this.store.formattedPhone.replace(/-/g, '').match(/(\d{1,10})/g)[0];

                // Format display value based on calculated currencyValue
                this.store.formattedPhone = this.store.phone.replace(/(\d{1,3})(\d{1,3})(\d{1,4})/g, '$1-$2-$3');                
            },
            next(stepForm) {
                this.$refs[stepForm].validate((valid) => {
                    if (valid)
                        this.currentStep++;
                });                     
            },            
            prev() {
                this.currentStep--;
            },
            validateForm(form) {
                let isValid = false;
                this.$refs[form].validate((valid) => {
                    isValid = valid;
                });
                return isValid;
            },
            setCropData(data) {
                this.store.imageCropData = data;
            },
            setSelectedImage(file) {
                this.file = file;
            },
            confirmEmail() {                
                    this.loading = true;
                    axios.post('/api/users/VerifyEmail', { email: this.store.email, firstName: this.store.firstName, lastName: this.store.lastName }).then(response => {
                        this.loading = false;
                        if (response.data) {
                            this.store.firstName = response.data.firstName;
                            this.store.lastName = response.data.lastName;
                            this.emailExists = true;
                        }
                        else {
                            this.emailExists = false;
                        }
                        //this.currentStep++;
                    }).catch((error) => {
                        this.errorMessage = error.response.data;
                        this.loading = false;
                        if (this.errorMessage == 'Okay, it looks like you are already registered your user account associated with this email.') {
                            this.emailExists = true;
                        }
                    });                
            },
            authenticateLogin() {
                if (this.userExists) {
                    this.loading = true;
                    const loginUrl = "/api/users/authenticate";
                    this.login.mobile = this.store.formattedPhone;
                    axios.post(loginUrl, this.login).then(response => {
                        this.loading = false;
                        if (response.status === 200) {
                            store.dispatch("login", response);
                        }
                        else {
                            this.errorMessage = response.message;
                        }
                    }).catch((error) => {
                        this.loading = false;
                        if (error && error.response && error.response.data)
                            this.errorMessage = error.response.data.message;
                    });
                }               
            },
            sendVerificationCode() {
                if (this.validateForm('step3Form') === true) {
                    this.loading = true;
                    axios.post('/api/users/SendVerifyEmailCodeParticipant', { email: this.store.email, firstName: this.store.firstName, lastName: this.store.lastName }).then(response => {
                        this.loading = false;
                        this.store.encryptedEmailcode = response.data;
                        this.currentStep++;
                    }).catch((error) => {
                        this.errorMessage = error.response.data;
                        this.loading = false;
                        if (this.errorMessage == 'Okay, it looks like you are already signed up, please sign in with your existing email address, once your signed in, you can choose to Create a New Fundraiser from the menu, if you do not have an existing event already running.') {
                            this.emailExists = true;
                        }
                    });
                }
            },
            async resendConfirmEmailCode() {
                this.loading = true;
                await axios.post('/api/users/SendVerifyEmailCodeParticipant', { email: this.store.email, firstName: this.store.firstName, lastName: this.store.lastName }).then(response => {
                    this.loading = false;
                    if (response.status === 200) {
                        this.store.encryptedEmailcode = response.data;
                    }
                }).catch((error) => {
                    this.errorMessage = error.response.data;
                    this.loading = false;
                });
            },
            async confirmEmailCode() {
                this.loading = true;
                this.errorMessage = '';
                await axios.post('/api/users/VerifyEmailCode', { code: this.store.emailCode, encryptedCode: this.store.encryptedEmailcode }).then(response => {
                    this.loading = false;
                    this.currentStep++;
                }).catch((error) => {
                    this.errorMessage = error.response.data;
                    this.loading = false;
                });
            },
            sendConfirmPhoneCode() {
                if (this.validateForm('step3Form') === true) {
                    this.loading = true;
                    axios.post('/api/users/sendverifymobilecode', { phone: this.store.formattedPhone }).then(response => {
                        this.loading = false;
                        this.currentStep++;
                    }).catch((error) => {
                        this.errorMessage1 = error.response.data;
                        this.loading = false;
                    });
                }
            },
            async confirmPhoneCode() {
                this.loading = true;
                this.errorMessage = '';
                await axios.post('/api/users/verifymobilecode', { phone: this.store.formattedPhone, code: this.store.phoneCode }).then(response => {
                    this.statusMessage = response.data.statusMessage;
                    this.userExists = response.data.userExists;
                    if (this.statusMessage == "approved") {
                        this.loading = false;
                        this.currentStep++;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.errorMessage1 = error.response.data;
                    this.loading = false;
                });
            },
            async resendConfirmPhoneCode() {
                this.loading = true;
                await axios.post('/api/users/sendverifymobilecode', { phone: this.store.formattedPhone }).then(response => {
                    this.loading = false;
                    if (response.status === 200) {
                        this.$message({
                            message: 'SMS login code resent.',
                            type: 'success'
                        });
                    }
                }).catch((error) => {
                    this.errorMessage1 = error.response.data;
                    this.loading = false;
                });
            },
            async submitStore() {   
                if (this.validateForm('step5Form') === true) {
                    //set file
                    var formData = new FormData();
                    if (this.file != null) {
                        formData.append("file", this.file.raw);
                        for (const [key, value] of Object.entries(this.store.imageCropData)) {
                            formData.append("imageCropData." + key, value);
                        }
                    }

                    for (const [key, value] of Object.entries(this.store)) {
                        if (key === "imageCropData")
                            continue;
                        formData.append(key, value);
                    }
                    for (const [key, value] of Object.entries(this.store.imageCropData)) {
                        formData.append("imageCropData." + key, value);
                    }

                    this.loading = true;
                    await axios.post('/api/Participants/signupApp', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(response => {
                        this.userExists = true;
                        this.loading = false;
                        this.currentStep++;                        
                    }).catch((error) => {
                        this.errorMessage1 = error.response.data;
                        this.loading = false;
                    });
                }
            },
            gotoLogin() {
                router.push('/login');
            }
        },
        computed:{
            form1Valid() {
                let isValid = true;
                //if (!this.isMounted)
                //    return false;
                //this.$refs['step1Form'].validate((valid) => {
                //    isValid = valid;
                //    });
                return isValid;
            },
            ...mapGetters([
                'currentUserDetail'
            ])
        },
        async mounted() {
            this.store.code = this.$route.params.code;
            
            //check code
            if (this.$route.params.code != null) {
                await axios.get('/api/participants/CheckInviteCode?code=' + this.store.code).then(response => {
                    this.store.organizerName = response.data.organizerName;
                    this.store.email = response.data.email;
                    this.store.phone = response.data.phone;
                    this.store.eventId = response.data.event.id;
                    this.event = response.data.event;
                    this.proceed = true;
                }).catch((error) => {
                    this.proceedErrorMessage = error.response.data;
                    this.proceed = false;
                });
            }
            else if (this.$route.params.mobile != null) {
                await axios.get('/api/participants/CheckAppInviteCode/' + this.$route.params.mobile).then(response => {
                    this.store.organizerName = response.data.organizerName;
                    this.store.eventId = response.data.event.id;
                    this.event = response.data.event;
                    this.proceed = true;
                }).catch((error) => {
                    this.proceedErrorMessage = error.response.data;
                    this.proceed = false;
                });
            }
           
        },
        created() {
            this.store.email = this.currentUserDetail.email;
            this.store.formattedphone = this.currentUserDetail.phone;
            this.store.phone = this.currentUserDetail.phone;
            this.store.firstName = this.currentUserDetail.firstName;
            this.store.lastName = this.currentUserDetail.lastName;
        }
    }
</script>


<style scoped>
    .email-code {
        text-align: center;
        font-size:20px;
        width:45%;
    }
    button.facebook-button {
        background-color:#3b579d;
        border:0;
        border-radius:3px;
        padding:15px 20px;
        color:white;
        font-size:1rem;
        min-width:250px;
    }
    button.google-button {
        background-color: #DB4437;
        border: 0;
        border-radius: 3px;
        padding: 15px 20px;
        color: white;
        font-size: 1rem;
        min-width: 250px;
    }
            button.facebook-button i, button.google-button i {
                font-size: 1.3rem;
            }
</style>
